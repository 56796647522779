
const apiPrefix = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`;
const config = {
    FRONTEND_URL: process.env.REACT_APP_FRONTEND_URL,
    API_URL: process.env.REACT_APP_API_URL,
    VERSION: process.env.REACT_APP_API_VERSION,
    EXPERIENCE_CENTER_IMG_URL: `${process.env.REACT_APP_API_URL}/experienceCenter`,
    NEWS_AND_EVENT_IMG_URL: `${process.env.REACT_APP_API_URL}/newsAndEvent`,
    DIGITAL_SHADE_CARD_IMG_URL: `${process.env.REACT_APP_API_URL}/digitalShadeCard`,
    PRIVATE_DIGITAL_SHADE_CARD_IMG_URL: `${process.env.REACT_APP_API_URL}/privateDigitalShadeCard`,
    INVESTOR_RELATION_IMG_URL: `${process.env.REACT_APP_API_URL}/investorRelation`,
    RESUME_IMG_URL: `${process.env.REACT_APP_API_URL}/resume`,
    PRODUCT_IMG_URL: `${process.env.REACT_APP_API_URL}/products`,
    PRODUCT_IMG_TEMP_URL: `${process.env.REACT_APP_API_URL}/temp`,
    apiPrefix,
};


export default config;
