import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import {
    contactUsSubmissions as contactUsSubmissionssApi,
} from '../../helpers';

// actions
import { contactUsSubmissionsApiResponseSuccess, contactUsSubmissionsApiResponseError } from './actions';

// constants
import { ContactUsSubmissionsActionTypes } from './constants';

interface ContactUsSubmissionsData {
    payload: {
        search: string;
        sortBy: string,
        limit: number,
        page: number,
        //
        id: string,
        title: string
    };
    type: string;
}

/**
 * ContactUsSubmissions list
 */
function* contactUsSubmissionsList({ payload: {
    search,
    sortBy,
    limit,
    page
} }: ContactUsSubmissionsData): SagaIterator {
    try {

        const response = yield call(contactUsSubmissionssApi, {
            search,
            sortBy,
            limit,
            page
        });
        const responseData = {
            data: response.data.results,
            page: response.data.page,
            limit: response.data.limit,
            totalPages: response.data.totalPages,
            totalResults: response.data.totalResults,
        }
        
        yield put(contactUsSubmissionsApiResponseSuccess({
            actionType: ContactUsSubmissionsActionTypes.CONTACT_US_SUBMISSIONS_LIST,
            responseData
        }));
    } catch (error: any) {
        yield put(contactUsSubmissionsApiResponseError(ContactUsSubmissionsActionTypes.CONTACT_US_SUBMISSIONS_LIST, error));

    }
}

export function* watchContactUsSubmissionsList() {
    yield takeEvery(ContactUsSubmissionsActionTypes.CONTACT_US_SUBMISSIONS_LIST, contactUsSubmissionsList);
}

function* contactUsSubmissionsSaga() {
    yield all([fork(watchContactUsSubmissionsList)]);
}

export default contactUsSubmissionsSaga;
