// constants
import { InvestorRelationActionTypes } from './constants';

const INIT_STATE = {
    investorRelations: [],
    investorRelation: {},
    page: 1,
    limit: 10,
    totalPages: 0,
    totalResults: 0,
    loading: false,
    hasErrors: false,
    submitted: false,
    isDeleted: false,
};

interface InvestorRelationActionType {
    type:
    | InvestorRelationActionTypes.API_RESPONSE_SUCCESS
    | InvestorRelationActionTypes.API_RESPONSE_ERROR
    | InvestorRelationActionTypes.INVESTOR_RELATION_LIST
    | InvestorRelationActionTypes.ADD_INVESTOR_RELATION
    | InvestorRelationActionTypes.SHOW_INVESTOR_RELATION
    | InvestorRelationActionTypes.UPDATE_INVESTOR_RELATION

    payload: {
        actionType?: string;
        responseData?: any;
        error?: any;
        validationError?: { error: string; key: string; }[];
        hasErrors: boolean,
        submitted: boolean,
        isDeleted: boolean,
    };
}

interface State {
    investorRelations: any;
    investorRelation: any;
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
}

const InvestorRelation = (state: State = INIT_STATE, action: InvestorRelationActionType): any => {
    switch (action.type) {
        case InvestorRelationActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload?.actionType) {  
                case InvestorRelationActionTypes.INVESTOR_RELATION_LIST: {
                    return {
                        ...state,
                        investorRelations: action.payload?.responseData?.data,
                        page: action.payload?.responseData?.page,
                        limit: action.payload?.responseData?.limit,
                        totalPages: action.payload?.responseData?.totalPages,
                        totalResults: action.payload?.responseData?.totalResults,
                        loading: true,
                        submitted: false,
                    };
                }
                case InvestorRelationActionTypes.ADD_INVESTOR_RELATION: {
                    return {
                        ...state,
                        investorRelations: [...state.investorRelations, action.payload?.responseData?.data],
                        totalResults: (action.payload?.responseData?.totalResults ? (action.payload?.responseData?.totalResults + 1) : 0),
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case InvestorRelationActionTypes.SHOW_INVESTOR_RELATION: {
                    return {
                        ...state,
                        investorRelation: action.payload?.responseData?.data,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case InvestorRelationActionTypes.UPDATE_INVESTOR_RELATION: {
                    const updatedInvestorRelation = action.payload?.responseData?.data;
                    const allSubs = state.investorRelations;
                    const updatedSubs= allSubs.map((cat: any) => cat.id === updatedInvestorRelation.id ? updatedInvestorRelation : cat);
                    return {
                        ...state,
                        investorRelations: updatedSubs,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case InvestorRelationActionTypes.DELETE_INVESTOR_RELATION: {
                    const deletedId = action.payload?.responseData?.deletedId;
                    const allSubs = state.investorRelations;
                    const updatedSubs = allSubs.filter((cat: any) => cat.id !== deletedId);
                    return {
                        ...state,
                        investorRelations: updatedSubs,
                        loading: true,
                        hasErrors: false,
                        submitted: false,
                        isDeleted: true,
                    };
                }
                default:
                    return { ...state };
            }

        case InvestorRelationActionTypes.API_RESPONSE_ERROR:
            switch (action.payload?.actionType) {
                case InvestorRelationActionTypes.INVESTOR_RELATION_LIST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: false,
                    };
                }
                case InvestorRelationActionTypes.ADD_INVESTOR_RELATION: {
                    return {
                        ...state,
                        error: action.payload?.error,
                        validationError: action.payload?.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case InvestorRelationActionTypes.SHOW_INVESTOR_RELATION: {
                    return {
                        ...state,
                        error: action.payload?.error,
                        validationError: action.payload?.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case InvestorRelationActionTypes.UPDATE_INVESTOR_RELATION: {
                    return {
                        ...state,
                        error: action.payload?.error,
                        validationError: action.payload?.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default InvestorRelation;
