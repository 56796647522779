// constants
import { DigitalShadeCardActionTypes } from './constants';

export interface DigitalShadeCardActionType {
    type:
    | DigitalShadeCardActionTypes.API_RESPONSE_SUCCESS
    | DigitalShadeCardActionTypes.API_RESPONSE_ERROR
    | DigitalShadeCardActionTypes.DIGITAL_SHADE_CARD_LIST
    | DigitalShadeCardActionTypes.ADD_DIGITAL_SHADE_CARD
    | DigitalShadeCardActionTypes.UPDATE_DIGITAL_SHADE_CARD
    | DigitalShadeCardActionTypes.SHOW_DIGITAL_SHADE_CARD
    | DigitalShadeCardActionTypes.DELETE_DIGITAL_SHADE_CARD
    payload: {} | string;
}

interface FiltersData {
    status: string,
    sortBy: string,
    limit: number,
    page: number,
    search: string
};

interface FormData {
    id: string,
    status: string,
    title: string,
    bookSize: string,
    category: string,
    images: Array<any>,
    pdf: string,
};


interface ResponseData {
    actionType: string,
    responseData: any,
}


// common success
export const digitalShadeCardApiResponseSuccess = ({ actionType, responseData }: ResponseData): DigitalShadeCardActionType => ({
    type: DigitalShadeCardActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, responseData },
});
// common error
export const digitalShadeCardApiResponseError = (actionType: string, error: string): DigitalShadeCardActionType => ({
    type: DigitalShadeCardActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const createDigitalShadeCard = ({
    title,
    bookSize,
    category,
    images,
    status,
    pdf
}: FormData): DigitalShadeCardActionType => ({
    type: DigitalShadeCardActionTypes.ADD_DIGITAL_SHADE_CARD,
    payload: {
        title,
        bookSize,
        category,
        status,
        images,
        pdf
    },
});


export const digitalShadeCardList = ({
    status,
    sortBy,
    limit,
    page,
    search,
}: FiltersData): DigitalShadeCardActionType => ({
    type: DigitalShadeCardActionTypes.DIGITAL_SHADE_CARD_LIST,
    payload: {
        search,
        status,
        sortBy,
        limit,
        page,
    },
});

export const showDigitalShadeCard = (id: string): DigitalShadeCardActionType => ({
    type: DigitalShadeCardActionTypes.SHOW_DIGITAL_SHADE_CARD,
    payload: { id },
});

export const destroyDigitalShadeCard = (id: string): DigitalShadeCardActionType => ({
    type: DigitalShadeCardActionTypes.DELETE_DIGITAL_SHADE_CARD,
    payload: { id },
});

export const updateDigitalShadeCard = ({
    id,
    status,
    title,
    bookSize,
    category,
    images,
    pdf,
}: FormData): DigitalShadeCardActionType => ({
    type: DigitalShadeCardActionTypes.UPDATE_DIGITAL_SHADE_CARD,
    payload: {
        id,
        status,
        title,
        bookSize,
        category,
        images,
        pdf,
    },
});




