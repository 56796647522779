// constants
import { JobTitlesActionTypes } from './constants';

export interface JobTitlesActionType {
    type:
    | JobTitlesActionTypes.API_RESPONSE_SUCCESS
    | JobTitlesActionTypes.API_RESPONSE_ERROR
    | JobTitlesActionTypes.JOB_TITLES_LIST
    | JobTitlesActionTypes.ADD_JOB_TITLES
    | JobTitlesActionTypes.UPDATE_JOB_TITLES
    | JobTitlesActionTypes.SHOW_JOB_TITLES
    | JobTitlesActionTypes.DELETE_JOB_TITLES
    payload: {} | string;
}

interface FiltersData {
    status: string,
    sortBy: string,
    limit: number,
    page: number,
    search: string
};

interface FormData {
    id: string,
    status: string,
    title: string
};


interface ResponseData {
    actionType: string,
    responseData: any,
}


// common success
export const jobTitlesApiResponseSuccess = ({ actionType, responseData }: ResponseData): JobTitlesActionType => ({
    type: JobTitlesActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, responseData },
});
// common error
export const jobTitlesApiResponseError = (actionType: string, error: string): JobTitlesActionType => ({
    type: JobTitlesActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const createJobTitles = ({
    title,
    status,
}: FormData): JobTitlesActionType => ({
    type: JobTitlesActionTypes.ADD_JOB_TITLES,
    payload: {
        title,
        status
    },
});


export const jobTitlesList = ({
    status,
    sortBy,
    limit,
    page,
    search,
}: FiltersData): JobTitlesActionType => ({
    type: JobTitlesActionTypes.JOB_TITLES_LIST,
    payload: {
        search,
        status,
        sortBy,
        limit,
        page,
    },
});

export const showJobTitles = (id: string): JobTitlesActionType => ({
    type: JobTitlesActionTypes.SHOW_JOB_TITLES,
    payload: { id },
});

export const destroyJobTitles = (id: string): JobTitlesActionType => ({
    type: JobTitlesActionTypes.DELETE_JOB_TITLES,
    payload: { id },
});

export const updateJobTitles = ({
    id,
    status,
    title
}: FormData): JobTitlesActionType => ({
    type: JobTitlesActionTypes.UPDATE_JOB_TITLES,
    payload: {
        id,
        status,
        title
    },
});




