import { APICore } from './apiCore';
const api = new APICore();

// privatePrivateDigitalShadeCard
function privateDigitalShadeCards(params: {
    status: string,
    search: string,
    sortBy: string,
    limit: number,
    page: number,
}) {
    const baseUrl = `/private-digital-shade-card`;
    return api.get(`${baseUrl}`, params);

}
function addPrivateDigitalShadeCard(params: {
    status: string,
    title: string,
    bookSize: string,
    email: string,
    phone: string,
    expiry: string,
    password: string,
    companyName: string,
    images: Array<any>,
    pdf: string,
}) {
    const baseUrl = `/private-digital-shade-card`;
    return api.create(`${baseUrl}`, params);
}

function updatePrivateDigitalShadeCard(params: {
    status: string,
    title: string,
    bookSize: string,
    email: string,
    phone: string,
    expiry: string,
    password: string,
    companyName: string,
    images: Array<any>,
    pdf: string,
}, id: string) {
    const baseUrl = `/private-digital-shade-card/${id}`;
    return api.updatePatch(`${baseUrl}`, params);

}

function deletePrivateDigitalShadeCard(id: string) {
    const baseUrl = `/private-digital-shade-card/${id}`;
    return api.delete(`${baseUrl}`);
}

function showPrivateDigitalShadeCard(params: {}, id: string) {
    const baseUrl = `/private-digital-shade-card/${id}`;
    return api.get(`${baseUrl}`, params);
}


function uploadPrivateDigitalShadeCardImage(formData: FormData) {
    const baseUrl = `/private-digital-shade-card/uploadImages`;
    return api.uploadMultipleFile(`${baseUrl}`, formData);
}

function removePrivateDigitalShadeCardImage(params: {
    fileName: string,
    folder: string
    productId: string
}) {
    const baseUrl = `/private-digital-shade-card/deleteImage`;
    return api.create(`${baseUrl}`, params);

}

export {
    privateDigitalShadeCards,
    addPrivateDigitalShadeCard,
    updatePrivateDigitalShadeCard,
    deletePrivateDigitalShadeCard,
    showPrivateDigitalShadeCard,
    uploadPrivateDigitalShadeCardImage,
    removePrivateDigitalShadeCardImage,
};
