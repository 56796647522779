// constants
import { ExperienceCenterActionTypes } from './constants';

const INIT_STATE = {
    experienceCenters: [],
    experienceCenter: {},
    page: 1,
    limit: 10,
    totalPages: 0,
    totalResults: 0,
    loading: false,
    hasErrors: false,
    submitted: false,
    isDeleted: false,
};

interface ExperienceCenterActionType {
    type:
    | ExperienceCenterActionTypes.API_RESPONSE_SUCCESS
    | ExperienceCenterActionTypes.API_RESPONSE_ERROR
    | ExperienceCenterActionTypes.EXPERIENCE_CENTER_LIST
    | ExperienceCenterActionTypes.ADD_EXPERIENCE_CENTER
    | ExperienceCenterActionTypes.SHOW_EXPERIENCE_CENTER
    | ExperienceCenterActionTypes.UPDATE_EXPERIENCE_CENTER

    payload: {
        actionType?: string;
        responseData?: any;
        error?: any;
        validationError?: { error: string; key: string; }[];
        hasErrors: boolean,
        submitted: boolean,
        isDeleted: boolean,
    };
}

interface State {
    experienceCenters: any;
    experienceCenter: any;
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
}

const ExperienceCenter = (state: State = INIT_STATE, action: ExperienceCenterActionType): any => {
    switch (action.type) {
        case ExperienceCenterActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload?.actionType) {  
                case ExperienceCenterActionTypes.EXPERIENCE_CENTER_LIST: {
                    return {
                        ...state,
                        experienceCenters: action.payload?.responseData?.data,
                        page: action.payload?.responseData?.page,
                        limit: action.payload?.responseData?.limit,
                        totalPages: action.payload?.responseData?.totalPages,
                        totalResults: action.payload?.responseData?.totalResults,
                        loading: true,
                        submitted: false,
                    };
                }
                case ExperienceCenterActionTypes.ADD_EXPERIENCE_CENTER: {
                    return {
                        ...state,
                        experienceCenters: [...state.experienceCenters, action.payload?.responseData?.data],
                        totalResults: (action.payload?.responseData?.totalResults ? (action.payload?.responseData?.totalResults + 1) : 0),
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case ExperienceCenterActionTypes.SHOW_EXPERIENCE_CENTER: {
                    return {
                        ...state,
                        experienceCenter: action.payload?.responseData?.data,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case ExperienceCenterActionTypes.UPDATE_EXPERIENCE_CENTER: {
                    const updatedExperienceCenter = action.payload?.responseData?.data;
                    const allSubs = state.experienceCenters;
                    const updatedSubs= allSubs.map((cat: any) => cat.id === updatedExperienceCenter.id ? updatedExperienceCenter : cat);
                    return {
                        ...state,
                        experienceCenters: updatedSubs,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case ExperienceCenterActionTypes.DELETE_EXPERIENCE_CENTER: {
                    const deletedId = action.payload?.responseData?.deletedId;
                    const allSubs = state.experienceCenters;
                    const updatedSubs = allSubs.filter((cat: any) => cat.id !== deletedId);
                    return {
                        ...state,
                        experienceCenters: updatedSubs,
                        loading: true,
                        hasErrors: false,
                        submitted: false,
                        isDeleted: true,
                    };
                }
                default:
                    return { ...state };
            }

        case ExperienceCenterActionTypes.API_RESPONSE_ERROR:
            switch (action.payload?.actionType) {
                case ExperienceCenterActionTypes.EXPERIENCE_CENTER_LIST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: false,
                    };
                }
                case ExperienceCenterActionTypes.ADD_EXPERIENCE_CENTER: {
                    return {
                        ...state,
                        error: action.payload?.error,
                        validationError: action.payload?.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case ExperienceCenterActionTypes.SHOW_EXPERIENCE_CENTER: {
                    return {
                        ...state,
                        error: action.payload?.error,
                        validationError: action.payload?.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case ExperienceCenterActionTypes.UPDATE_EXPERIENCE_CENTER: {
                    return {
                        ...state,
                        error: action.payload?.error,
                        validationError: action.payload?.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default ExperienceCenter;
