import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import {
    chatSubmissions as chatSubmissionssApi,
} from '../../helpers';

// actions
import { chatSubmissionsApiResponseSuccess, chatSubmissionsApiResponseError } from './actions';

// constants
import { ChatSubmissionsActionTypes } from './constants';

interface ChatSubmissionsData {
    payload: {
        search: string;
        sortBy: string,
        limit: number,
        page: number,
        //
        id: string,
        title: string
    };
    type: string;
}

/**
 * ChatSubmissions list
 */
function* chatSubmissionsList({ payload: {
    search,
    sortBy,
    limit,
    page
} }: ChatSubmissionsData): SagaIterator {
    try {

        const response = yield call(chatSubmissionssApi, {
            search,
            sortBy,
            limit,
            page
        });
        const responseData = {
            data: response.data.results,
            page: response.data.page,
            limit: response.data.limit,
            totalPages: response.data.totalPages,
            totalResults: response.data.totalResults,
        }
        
        yield put(chatSubmissionsApiResponseSuccess({
            actionType: ChatSubmissionsActionTypes.CHAT_SUBMISSIONS_LIST,
            responseData
        }));
    } catch (error: any) {
        yield put(chatSubmissionsApiResponseError(ChatSubmissionsActionTypes.CHAT_SUBMISSIONS_LIST, error));

    }
}

export function* watchChatSubmissionsList() {
    yield takeEvery(ChatSubmissionsActionTypes.CHAT_SUBMISSIONS_LIST, chatSubmissionsList);
}

function* chatSubmissionsSaga() {
    yield all([fork(watchChatSubmissionsList)]);
}

export default chatSubmissionsSaga;
