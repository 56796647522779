import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import {
    jobTitles as jobTitlessApi,
    addJobTitles as addJobTitlesApi,
    updateJobTitles as updateJobTitlesApi,
    showJobTitles as showJobTitlesApi,
    deleteJobTitles as deleteJobTitlesApi,
} from '../../../helpers';

// actions
import { jobTitlesApiResponseSuccess, jobTitlesApiResponseError } from './actions';

// constants
import { JobTitlesActionTypes } from './constants';

interface JobTitlesData {
    payload: {
        search: string;
        sortBy: string,
        limit: number,
        page: number,
        //
        id: string,
        status: string,
        title: string
    };
    type: string;
}

/**
 * JobTitles list
 */
function* jobTitlesList({ payload: {
    search,
    status,
    sortBy,
    limit,
    page
} }: JobTitlesData): SagaIterator {
    try {

        const response = yield call(jobTitlessApi, {
            search,
            status,
            sortBy,
            limit,
            page
        });
        const responseData = {
            data: response.data.results,
            page: response.data.page,
            limit: response.data.limit,
            totalPages: response.data.totalPages,
            totalResults: response.data.totalResults,
        }
        
        yield put(jobTitlesApiResponseSuccess({
            actionType: JobTitlesActionTypes.JOB_TITLES_LIST,
            responseData
        }));
    } catch (error: any) {
        yield put(jobTitlesApiResponseError(JobTitlesActionTypes.JOB_TITLES_LIST, error));

    }
}

/**
 * Add JobTitles
 */
function* addJobTitles({ payload: {
    title,
    status } }: JobTitlesData): SagaIterator {
    try {

        const response = yield call(addJobTitlesApi, {
            title,
            status
        });
        const responseData = {
            data: response.data.data,
        }
        yield put(jobTitlesApiResponseSuccess({
            actionType: JobTitlesActionTypes.ADD_JOB_TITLES,
            responseData
        }

        ));
    } catch (error: any) {
        yield put(jobTitlesApiResponseError(JobTitlesActionTypes.ADD_JOB_TITLES, error));

    }
}

/**
 * Show User
 */
function* showJobTitles({ payload: {
    id,
} }: JobTitlesData): SagaIterator {
    try {
        const response = yield call(showJobTitlesApi, {}, id);
        const responseData = {
            data: response.data,
        }
        yield put(jobTitlesApiResponseSuccess({
            actionType: JobTitlesActionTypes.SHOW_JOB_TITLES,
            responseData
        }
        ));
    } catch (error: any) {
        yield put(jobTitlesApiResponseError(JobTitlesActionTypes.SHOW_JOB_TITLES, error));

    }
}

/**
 * Update JobTitles
 */
function* updateJobTitles({ payload: {
    id,
    title,
    status} }: JobTitlesData): SagaIterator {
    try {
        const response = yield call(updateJobTitlesApi, {
            status,
            title
        }, id);
        const responseData = {
            data: response.data.data,
        }
        yield put(jobTitlesApiResponseSuccess({
            actionType: JobTitlesActionTypes.UPDATE_JOB_TITLES,
            responseData
        }
        ));
    } catch (error: any) {
        yield put(jobTitlesApiResponseError(JobTitlesActionTypes.UPDATE_JOB_TITLES, error));

    }
}
/**
 * Delete JobTitles
 */
function* deleteJobTitles({ payload: { id } }: JobTitlesData): SagaIterator {
    try {
        yield call(deleteJobTitlesApi, id);
        const responseData = {
            deletedId: id
        };
        yield put(jobTitlesApiResponseSuccess({
            actionType: JobTitlesActionTypes.DELETE_JOB_TITLES,
            responseData
        }));
    } catch (error: any) {
        yield put(jobTitlesApiResponseError(JobTitlesActionTypes.DELETE_JOB_TITLES, error));

    }
}



export function* watchJobTitlesList() {
    yield takeEvery(JobTitlesActionTypes.JOB_TITLES_LIST, jobTitlesList);
}

export function* watchAddJobTitles() {
    yield takeEvery(JobTitlesActionTypes.ADD_JOB_TITLES, addJobTitles);
}

export function* watchUpdateJobTitles() {
    yield takeEvery(JobTitlesActionTypes.UPDATE_JOB_TITLES, updateJobTitles);
}
export function* watchShowJobTitles() {
    yield takeEvery(JobTitlesActionTypes.SHOW_JOB_TITLES, showJobTitles);
}

export function* watchDeleteJobTitles() {
    yield takeEvery(JobTitlesActionTypes.DELETE_JOB_TITLES, deleteJobTitles);
}



function* jobTitlesSaga() {
    yield all([fork(watchJobTitlesList), fork(watchAddJobTitles), fork(watchShowJobTitles), fork(watchUpdateJobTitles), fork(watchDeleteJobTitles)]);
}

export default jobTitlesSaga;
