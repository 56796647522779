import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import {
    newsAndEvents as newsAndEventsApi,
    addNewsAndEvent as addNewsAndEventApi,
    updateNewsAndEvent as updateNewsAndEventApi,
    showNewsAndEvent as showNewsAndEventApi,
    deleteNewsAndEvent as deleteNewsAndEventApi,
} from '../../helpers';

// actions
import { newsAndEventApiResponseSuccess, newsAndEventApiResponseError } from './actions';

// constants
import { NewsAndEventActionTypes } from './constants';

interface NewsAndEventData {
    payload: {
        search: string;
        description: string;
        sortBy: string,
        limit: number,
        page: number,
        //
        id: string,
        status: string,
        title: string,
        image: string,
        shortContent: string
        content: string
    };
    type: string;
}

/**
 * NewsAndEvent list
 */
function* newsAndEventList({ payload: {
    search,
    status,
    sortBy,
    limit,
    page
} }: NewsAndEventData): SagaIterator {
    try {

        const response = yield call(newsAndEventsApi, {
            search,
            status,
            sortBy,
            limit,
            page
        });
        const responseData = {
            data: response.data.results,
            page: response.data.page,
            limit: response.data.limit,
            totalPages: response.data.totalPages,
            totalResults: response.data.totalResults,
        }
        yield put(newsAndEventApiResponseSuccess({
            actionType: NewsAndEventActionTypes.NEWS_AND_EVENT_LIST,
            responseData
        }));
    } catch (error: any) {
        yield put(newsAndEventApiResponseError(NewsAndEventActionTypes.NEWS_AND_EVENT_LIST, error));

    }
}

/**
 * Add NewsAndEvent
 */
function* addNewsAndEvent({ payload: {
    title,
    image,
    shortContent,
    content,
    status } }: NewsAndEventData): SagaIterator {
    try {

        const response = yield call(addNewsAndEventApi, {
            title,
            image,
            shortContent,
            content,
            status
        });
        const responseData = {
            data: response.data.data,
        }
        yield put(newsAndEventApiResponseSuccess({
            actionType: NewsAndEventActionTypes.ADD_NEWS_AND_EVENT,
            responseData
        }

        ));
    } catch (error: any) {
        yield put(newsAndEventApiResponseError(NewsAndEventActionTypes.ADD_NEWS_AND_EVENT, error));

    }
}

/**
 * Show User
 */
function* showNewsAndEvent({ payload: {
    id,
} }: NewsAndEventData): SagaIterator {
    try {
        const response = yield call(showNewsAndEventApi, {}, id);
        const responseData = {
            data: response.data,
        }
        yield put(newsAndEventApiResponseSuccess({
            actionType: NewsAndEventActionTypes.SHOW_NEWS_AND_EVENT,
            responseData
        }
        ));
    } catch (error: any) {
        yield put(newsAndEventApiResponseError(NewsAndEventActionTypes.SHOW_NEWS_AND_EVENT, error));

    }
}

/**
 * Update NewsAndEvent
 */
function* updateNewsAndEvent({ payload: {
    id,
    status,
    title,
    image,
    shortContent,
    content } }: NewsAndEventData): SagaIterator {
    try {

        const response = yield call(updateNewsAndEventApi, {
            status,
            title,
            image,
            shortContent,
            content,
        }, id);
        const responseData = {
            data: response.data.data,
        }
        yield put(newsAndEventApiResponseSuccess({
            actionType: NewsAndEventActionTypes.UPDATE_NEWS_AND_EVENT,
            responseData
        }
        ));
    } catch (error: any) {
        yield put(newsAndEventApiResponseError(NewsAndEventActionTypes.UPDATE_NEWS_AND_EVENT, error));

    }
}
/**
 * Delete NewsAndEvent
 */
function* deleteNewsAndEvent({ payload: { id } }: NewsAndEventData): SagaIterator {
    try {
        yield call(deleteNewsAndEventApi, id);
        const responseData = {
            deletedId: id
        };
        yield put(newsAndEventApiResponseSuccess({
            actionType: NewsAndEventActionTypes.DELETE_NEWS_AND_EVENT,
            responseData
        }));
    } catch (error: any) {
        yield put(newsAndEventApiResponseError(NewsAndEventActionTypes.DELETE_NEWS_AND_EVENT, error));

    }
}



export function* watchNewsAndEventList() {
    yield takeEvery(NewsAndEventActionTypes.NEWS_AND_EVENT_LIST, newsAndEventList);
}

export function* watchAddNewsAndEvent() {
    yield takeEvery(NewsAndEventActionTypes.ADD_NEWS_AND_EVENT, addNewsAndEvent);
}

export function* watchUpdateNewsAndEvent() {
    yield takeEvery(NewsAndEventActionTypes.UPDATE_NEWS_AND_EVENT, updateNewsAndEvent);
}
export function* watchShowNewsAndEvent() {
    yield takeEvery(NewsAndEventActionTypes.SHOW_NEWS_AND_EVENT, showNewsAndEvent);
}

export function* watchDeleteNewsAndEvent() {
    yield takeEvery(NewsAndEventActionTypes.DELETE_NEWS_AND_EVENT, deleteNewsAndEvent);
}



function* newsAndEventSaga() {
    yield all([fork(watchNewsAndEventList), fork(watchAddNewsAndEvent), fork(watchShowNewsAndEvent), fork(watchUpdateNewsAndEvent), fork(watchDeleteNewsAndEvent)]);
}

export default newsAndEventSaga;
