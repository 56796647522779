export enum JobTitlesActionTypes {
    API_RESPONSE_SUCCESS = '@@jobTitles/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@jobTitles/API_RESPONSE_ERROR',
    JOB_TITLES_LIST = '@@jobTitles/JOB_TITLES_LIST',
    ADD_JOB_TITLES = '@@jobTitles/ADD_JOB_TITLES',
    SHOW_JOB_TITLES = '@@jobTitles/SHOW_JOB_TITLES',
    UPDATE_JOB_TITLES = '@@jobTitles/UPDATE_JOB_TITLES',
    DELETE_JOB_TITLES = '@@jobTitles/DELETE_JOB_TITLES',
}

