import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import {
    chatFormSetups as chatFormSetupsApi,
    addChatFormSetup as addChatFormSetupApi,
    updateChatFormSetup as updateChatFormSetupApi,
    showChatFormSetup as showChatFormSetupApi,
    deleteChatFormSetup as deleteChatFormSetupApi,
} from '../../helpers';

// actions
import { chatFormSetupApiResponseSuccess, chatFormSetupApiResponseError } from './actions';

// constants
import { ChatFormSetupActionTypes } from './constants';

interface ChatFormSetupData {
    payload: {
        //
        _id: string,
        question: string,
        type: string,
        parentId: string
    };
    type: string;
}

/**
 * ChatFormSetup list
 */
function* chatFormSetupList({ payload: {
} }: ChatFormSetupData): SagaIterator {
    try {

        const response = yield call(chatFormSetupsApi, {});
        const responseData = {
            data: response.data.result,
        }
        yield put(chatFormSetupApiResponseSuccess({
            actionType: ChatFormSetupActionTypes.CHAT_SETUP_LIST,
            responseData
        }));
    } catch (error: any) {
        yield put(chatFormSetupApiResponseError(ChatFormSetupActionTypes.CHAT_SETUP_LIST, error));

    }
}

/**
 * Add ChatFormSetup
 */
function* addChatFormSetup({ payload: {
    question,
    type,
    parentId
} }: ChatFormSetupData): SagaIterator {
    try {

        const response = yield call(addChatFormSetupApi, {
            question,
            type,
            parentId
        });

        const responseData = {
            data: response.data.result,
        }
        yield put(chatFormSetupApiResponseSuccess({
            actionType: ChatFormSetupActionTypes.ADD_CHAT_SETUP,
            responseData
        }

        ));
    } catch (error: any) {
        yield put(chatFormSetupApiResponseError(ChatFormSetupActionTypes.ADD_CHAT_SETUP, error));

    }
}

/**
 * Show User
 */
function* showChatFormSetup({ payload: {
    _id,
} }: ChatFormSetupData): SagaIterator {
    try {
        const response = yield call(showChatFormSetupApi, {}, _id);
        const responseData = {
            data: response.data,
        }
        yield put(chatFormSetupApiResponseSuccess({
            actionType: ChatFormSetupActionTypes.SHOW_CHAT_SETUP,
            responseData
        }
        ));
    } catch (error: any) {
        yield put(chatFormSetupApiResponseError(ChatFormSetupActionTypes.SHOW_CHAT_SETUP, error));

    }
}

/**
 * Update ChatFormSetup
 */
function* updateChatFormSetup({ payload: {
    _id,
    question,
    type,
    parentId } }: ChatFormSetupData): SagaIterator {
    try {

        const response = yield call(updateChatFormSetupApi, {
            question,
            type,
            parentId
        }, _id);
        const responseData = {
            data: response.data.result,
        }
        yield put(chatFormSetupApiResponseSuccess({
            actionType: ChatFormSetupActionTypes.UPDATE_CHAT_SETUP,
            responseData
        }
        ));
    } catch (error: any) {
        yield put(chatFormSetupApiResponseError(ChatFormSetupActionTypes.UPDATE_CHAT_SETUP, error));

    }
}
/**
 * Delete ChatFormSetup
 */
function* deleteChatFormSetup({ payload: { _id } }: ChatFormSetupData): SagaIterator {
    try {
        const response = yield call(deleteChatFormSetupApi, _id);
        const responseData = {
            data: response.data.result,
        }
        yield put(chatFormSetupApiResponseSuccess({
            actionType: ChatFormSetupActionTypes.DELETE_CHAT_SETUP,
            responseData
        }));
    } catch (error: any) {
        yield put(chatFormSetupApiResponseError(ChatFormSetupActionTypes.DELETE_CHAT_SETUP, error));

    }
}



export function* watchChatFormSetupList() {
    yield takeEvery(ChatFormSetupActionTypes.CHAT_SETUP_LIST, chatFormSetupList);
}

export function* watchAddChatFormSetup() {
    yield takeEvery(ChatFormSetupActionTypes.ADD_CHAT_SETUP, addChatFormSetup);
}

export function* watchUpdateChatFormSetup() {
    yield takeEvery(ChatFormSetupActionTypes.UPDATE_CHAT_SETUP, updateChatFormSetup);
}
export function* watchShowChatFormSetup() {
    yield takeEvery(ChatFormSetupActionTypes.SHOW_CHAT_SETUP, showChatFormSetup);
}

export function* watchDeleteChatFormSetup() {
    yield takeEvery(ChatFormSetupActionTypes.DELETE_CHAT_SETUP, deleteChatFormSetup);
}



function* chatFormSetupSaga() {
    yield all([fork(watchChatFormSetupList), fork(watchAddChatFormSetup), fork(watchShowChatFormSetup), fork(watchUpdateChatFormSetup), fork(watchDeleteChatFormSetup)]);
}

export default chatFormSetupSaga;
