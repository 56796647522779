import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import {
    experienceCenters as experienceCentersApi,
    addExperienceCenter as addExperienceCenterApi,
    updateExperienceCenter as updateExperienceCenterApi,
    showExperienceCenter as showExperienceCenterApi,
    deleteExperienceCenter as deleteExperienceCenterApi,
} from '../../helpers';

// actions
import { experienceCenterApiResponseSuccess, experienceCenterApiResponseError } from './actions';

// constants
import { ExperienceCenterActionTypes } from './constants';

interface ExperienceCenterData {
    payload: {
        search: string;
        description: string;
        sortBy: string,
        limit: number,
        page: number,
        //
        id: string,
        status: string,
        productName: string,
        category: string,
        ci: string,
        productCode: string,
        contentOnColorant: string,
        lightFastness: string,
        image: string,
        heatStability: string,
        washingN: string,
        washingPES: string,
        washingPA: string,
        washingCO: string,
        sublimationN: string,
        sublimationPES: string,
        sublimationCO: string
    };
    type: string;
}

/**
 * ExperienceCenter list
 */
function* experienceCenterList({ payload: {
    search,
    status,
    sortBy,
    limit,
    page
} }: ExperienceCenterData): SagaIterator {
    try {

        const response = yield call(experienceCentersApi, {
            search,
            status,
            sortBy,
            limit,
            page
        });
        const responseData = {
            data: response.data.results,
            page: response.data.page,
            limit: response.data.limit,
            totalPages: response.data.totalPages,
            totalResults: response.data.totalResults,
        }
        yield put(experienceCenterApiResponseSuccess({
            actionType: ExperienceCenterActionTypes.EXPERIENCE_CENTER_LIST,
            responseData
        }));
    } catch (error: any) {
        yield put(experienceCenterApiResponseError(ExperienceCenterActionTypes.EXPERIENCE_CENTER_LIST, error));

    }
}

/**
 * Add ExperienceCenter
 */
function* addExperienceCenter({ payload: {
    productName,
    category,
    ci,
    productCode,
    contentOnColorant,
    lightFastness,
    image,
    heatStability,
    washingN,
    washingPES,
    washingPA,
    washingCO,
    sublimationN,
    sublimationPES,
    sublimationCO,
    status, } }: ExperienceCenterData): SagaIterator {
    try {

        const response = yield call(addExperienceCenterApi, {
            productName,
            category,
            ci,
            productCode,
            contentOnColorant,
            lightFastness,
            image,
            heatStability,
            washingN,
            washingPES,
            washingPA,
            washingCO,
            sublimationN,
            sublimationPES,
            sublimationCO,
            status,
        });
        const responseData = {
            data: response.data.data,
        }
        yield put(experienceCenterApiResponseSuccess({
            actionType: ExperienceCenterActionTypes.ADD_EXPERIENCE_CENTER,
            responseData
        }

        ));
    } catch (error: any) {
        yield put(experienceCenterApiResponseError(ExperienceCenterActionTypes.ADD_EXPERIENCE_CENTER, error));

    }
}

/**
 * Show User
 */
function* showExperienceCenter({ payload: {
    id,
} }: ExperienceCenterData): SagaIterator {
    try {
        const response = yield call(showExperienceCenterApi, {}, id);
        const responseData = {
            data: response.data,
        }
        yield put(experienceCenterApiResponseSuccess({
            actionType: ExperienceCenterActionTypes.SHOW_EXPERIENCE_CENTER,
            responseData
        }
        ));
    } catch (error: any) {
        yield put(experienceCenterApiResponseError(ExperienceCenterActionTypes.SHOW_EXPERIENCE_CENTER, error));

    }
}

/**
 * Update ExperienceCenter
 */
function* updateExperienceCenter({ payload: {
    id,
    status,
    productName,
    category,
    ci,
    productCode,
    contentOnColorant,
    lightFastness,
    image,
    heatStability,
    washingN,
    washingPES,
    washingPA,
    washingCO,
    sublimationN,
    sublimationPES,
    sublimationCO } }: ExperienceCenterData): SagaIterator {
    try {

        const response = yield call(updateExperienceCenterApi, {
            status,
            productName,
            category,
            ci,
            productCode,
            contentOnColorant,
            lightFastness,
            image,
            heatStability,
            washingN,
            washingPES,
            washingPA,
            washingCO,
            sublimationN,
            sublimationPES,
            sublimationCO
        }, id);
        const responseData = {
            data: response.data.data,
        }
        yield put(experienceCenterApiResponseSuccess({
            actionType: ExperienceCenterActionTypes.UPDATE_EXPERIENCE_CENTER,
            responseData
        }
        ));
    } catch (error: any) {
        yield put(experienceCenterApiResponseError(ExperienceCenterActionTypes.UPDATE_EXPERIENCE_CENTER, error));

    }
}
/**
 * Delete ExperienceCenter
 */
function* deleteExperienceCenter({ payload: { id } }: ExperienceCenterData): SagaIterator {
    try {
        yield call(deleteExperienceCenterApi, id);
        const responseData = {
            deletedId: id
        };
        yield put(experienceCenterApiResponseSuccess({
            actionType: ExperienceCenterActionTypes.DELETE_EXPERIENCE_CENTER,
            responseData
        }));
    } catch (error: any) {
        yield put(experienceCenterApiResponseError(ExperienceCenterActionTypes.DELETE_EXPERIENCE_CENTER, error));

    }
}



export function* watchExperienceCenterList() {
    yield takeEvery(ExperienceCenterActionTypes.EXPERIENCE_CENTER_LIST, experienceCenterList);
}

export function* watchAddExperienceCenter() {
    yield takeEvery(ExperienceCenterActionTypes.ADD_EXPERIENCE_CENTER, addExperienceCenter);
}

export function* watchUpdateExperienceCenter() {
    yield takeEvery(ExperienceCenterActionTypes.UPDATE_EXPERIENCE_CENTER, updateExperienceCenter);
}
export function* watchShowExperienceCenter() {
    yield takeEvery(ExperienceCenterActionTypes.SHOW_EXPERIENCE_CENTER, showExperienceCenter);
}

export function* watchDeleteExperienceCenter() {
    yield takeEvery(ExperienceCenterActionTypes.DELETE_EXPERIENCE_CENTER, deleteExperienceCenter);
}



function* experienceCenterSaga() {
    yield all([fork(watchExperienceCenterList), fork(watchAddExperienceCenter), fork(watchShowExperienceCenter), fork(watchUpdateExperienceCenter), fork(watchDeleteExperienceCenter)]);
}

export default experienceCenterSaga;
