import { APICore } from './apiCore';
const api = new APICore();

// investorRelation
function investorRelations(params: {
    status: string,
    search: string,
    sortBy: string,
    limit: number,
    page: number,
}) {
    const baseUrl = `/investor-relations`;
    return api.get(`${baseUrl}`, params);

}
function addInvestorRelation(params: {
    status: string,
    title: string,
    order: number,
    tabs: Array<any>,
    relationType: string,
}) {
    const baseUrl = `/investor-relations`;
    return api.create(`${baseUrl}`, params);
}

function updateInvestorRelation(params: {
    status: string,
    title: string,
    order: number,
    tabs: Array<any>,
    relationType: string,
}, id: string) {
    const baseUrl = `/investor-relations/${id}`;
    return api.updatePatch(`${baseUrl}`, params);

}

function deleteInvestorRelation(id: string) {
    const baseUrl = `/investor-relations/${id}`;
    return api.delete(`${baseUrl}`);
}

function showInvestorRelation(params: {}, id: string) {
    const baseUrl = `/investor-relations/${id}`;
    return api.get(`${baseUrl}`, params);
}


function uploadInvestorRelationImage(formData: FormData) {
    const baseUrl = `/investor-relations/uploadImages`;
    return api.uploadMultipleFile(`${baseUrl}`, formData);
}

function removeInvestorRelationImage(params: {
    fileName: string,
    folder: string
    productId: string
}) {
    const baseUrl = `/investor-relations/deleteImage`;
    return api.create(`${baseUrl}`, params);

}

export {
    investorRelations,
    addInvestorRelation,
    updateInvestorRelation,
    deleteInvestorRelation,
    showInvestorRelation,
    uploadInvestorRelationImage,
    removeInvestorRelationImage,
};
