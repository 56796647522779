// constants
import { InvestorRelationActionTypes } from './constants';

export interface InvestorRelationActionType {
    type:
    | InvestorRelationActionTypes.API_RESPONSE_SUCCESS
    | InvestorRelationActionTypes.API_RESPONSE_ERROR
    | InvestorRelationActionTypes.INVESTOR_RELATION_LIST
    | InvestorRelationActionTypes.ADD_INVESTOR_RELATION
    | InvestorRelationActionTypes.UPDATE_INVESTOR_RELATION
    | InvestorRelationActionTypes.SHOW_INVESTOR_RELATION
    | InvestorRelationActionTypes.DELETE_INVESTOR_RELATION
    payload: {} | string;
}

interface FiltersData {
    status: string,
    sortBy: string,
    limit: number,
    page: number,
    search: string
};

interface FormData {
    id: string,
    status: string,
    order: number,
    relationType: string,
    title: string,
    tabs: Array<any>,
};


interface ResponseData {
    actionType: string,
    responseData: any,
}


// common success
export const investorRelationApiResponseSuccess = ({ actionType, responseData }: ResponseData): InvestorRelationActionType => ({
    type: InvestorRelationActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, responseData },
});
// common error
export const investorRelationApiResponseError = (actionType: string, error: string): InvestorRelationActionType => ({
    type: InvestorRelationActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const createInvestorRelation = ({
    title,
    status,
    order,
    relationType,
    tabs
}: FormData): InvestorRelationActionType => ({
    type: InvestorRelationActionTypes.ADD_INVESTOR_RELATION,
    payload: {
        title,
        order,
        status,
        relationType,
        tabs
    },
});


export const investorRelationList = ({
    status,
    sortBy,
    limit,
    page,
    search,
}: FiltersData): InvestorRelationActionType => ({
    type: InvestorRelationActionTypes.INVESTOR_RELATION_LIST,
    payload: {
        search,
        status,
        sortBy,
        limit,
        page,
    },
});

export const showInvestorRelation = (id: string): InvestorRelationActionType => ({
    type: InvestorRelationActionTypes.SHOW_INVESTOR_RELATION,
    payload: { id },
});

export const destroyInvestorRelation = (id: string): InvestorRelationActionType => ({
    type: InvestorRelationActionTypes.DELETE_INVESTOR_RELATION,
    payload: { id },
});

export const updateInvestorRelation = ({
    id,
    status,
    relationType,
    title,
    order,
    tabs
}: FormData): InvestorRelationActionType => ({
    type: InvestorRelationActionTypes.UPDATE_INVESTOR_RELATION,
    payload: {
        id,
        status,
        relationType,
        title,
        order,
        tabs
    },
});




