export enum DigitalShadeCardActionTypes {
    API_RESPONSE_SUCCESS = '@@digitalShadeCard/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@digitalShadeCard/API_RESPONSE_ERROR',
    DIGITAL_SHADE_CARD_LIST = '@@digitalShadeCard/DIGITAL_SHADE_CARD_LIST',
    ADD_DIGITAL_SHADE_CARD = '@@digitalShadeCard/ADD_DIGITAL_SHADE_CARD',
    SHOW_DIGITAL_SHADE_CARD = '@@digitalShadeCard/SHOW_DIGITAL_SHADE_CARD',
    UPDATE_DIGITAL_SHADE_CARD = '@@digitalShadeCard/UPDATE_DIGITAL_SHADE_CARD',
    DELETE_DIGITAL_SHADE_CARD = '@@digitalShadeCard/DELETE_DIGITAL_SHADE_CARD',
}

