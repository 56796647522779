import { APICore } from './apiCore';
const api = new APICore();

// jobSubmissions
function createYourOwn(params: {
    search: string,
    sortBy: string,
    limit: number,
    page: number,
}) {
    const baseUrl = `/create-your-own`;
    return api.get(`${baseUrl}`, params);

}

export {
    createYourOwn
};
