import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import {
    digitalShadeCards as digitalShadeCardsApi,
    addDigitalShadeCard as addDigitalShadeCardApi,
    updateDigitalShadeCard as updateDigitalShadeCardApi,
    showDigitalShadeCard as showDigitalShadeCardApi,
    deleteDigitalShadeCard as deleteDigitalShadeCardApi,
} from '../../helpers';

// actions
import { digitalShadeCardApiResponseSuccess, digitalShadeCardApiResponseError } from './actions';

// constants
import { DigitalShadeCardActionTypes } from './constants';

interface DigitalShadeCardData {
    payload: {
        search: string;
        description: string;
        sortBy: string,
        limit: number,
        page: number,
        //
        id: string,
        status: string,
        title: string,
        bookSize: string,
        category: string,
        images: Array<any>,
        pdf: string,
    };
    type: string;
}

/**
 * DigitalShadeCard list
 */
function* digitalShadeCardList({ payload: {
    search,
    status,
    sortBy,
    limit,
    page
} }: DigitalShadeCardData): SagaIterator {
    try {

        const response = yield call(digitalShadeCardsApi, {
            search,
            status,
            sortBy,
            limit,
            page
        });
        const responseData = {
            data: response.data.results,
            page: response.data.page,
            limit: response.data.limit,
            totalPages: response.data.totalPages,
            totalResults: response.data.totalResults,
        }
        yield put(digitalShadeCardApiResponseSuccess({
            actionType: DigitalShadeCardActionTypes.DIGITAL_SHADE_CARD_LIST,
            responseData
        }));
    } catch (error: any) {
        yield put(digitalShadeCardApiResponseError(DigitalShadeCardActionTypes.DIGITAL_SHADE_CARD_LIST, error));

    }
}

/**
 * Add DigitalShadeCard
 */
function* addDigitalShadeCard({ payload: {
    title,
    bookSize,
    category,
    status,
    pdf,
    images } }: DigitalShadeCardData): SagaIterator {
    try {

        const response = yield call(addDigitalShadeCardApi, {
            bookSize,
            category,
            title,
            status,
            pdf,
            images
        });
        const responseData = {
            data: response.data.data,
        }
        yield put(digitalShadeCardApiResponseSuccess({
            actionType: DigitalShadeCardActionTypes.ADD_DIGITAL_SHADE_CARD,
            responseData
        }

        ));
    } catch (error: any) {
        yield put(digitalShadeCardApiResponseError(DigitalShadeCardActionTypes.ADD_DIGITAL_SHADE_CARD, error));

    }
}

/**
 * Show User
 */
function* showDigitalShadeCard({ payload: {
    id,
} }: DigitalShadeCardData): SagaIterator {
    try {
        const response = yield call(showDigitalShadeCardApi, {}, id);
        const responseData = {
            data: response.data,
        }
        yield put(digitalShadeCardApiResponseSuccess({
            actionType: DigitalShadeCardActionTypes.SHOW_DIGITAL_SHADE_CARD,
            responseData
        }
        ));
    } catch (error: any) {
        yield put(digitalShadeCardApiResponseError(DigitalShadeCardActionTypes.SHOW_DIGITAL_SHADE_CARD, error));

    }
}

/**
 * Update DigitalShadeCard
 */
function* updateDigitalShadeCard({ payload: {
    id,
    title,
    bookSize,
    category,
    status,
    pdf,
    images } }: DigitalShadeCardData): SagaIterator {
    try {
        const response = yield call(updateDigitalShadeCardApi, {
            status,
            bookSize,
            category,
            title,
            images,
            pdf,
        }, id);
        const responseData = {
            data: response.data.data,
        }
        yield put(digitalShadeCardApiResponseSuccess({
            actionType: DigitalShadeCardActionTypes.UPDATE_DIGITAL_SHADE_CARD,
            responseData
        }
        ));
    } catch (error: any) {
        yield put(digitalShadeCardApiResponseError(DigitalShadeCardActionTypes.UPDATE_DIGITAL_SHADE_CARD, error));

    }
}
/**
 * Delete DigitalShadeCard
 */
function* deleteDigitalShadeCard({ payload: { id } }: DigitalShadeCardData): SagaIterator {
    try {
        yield call(deleteDigitalShadeCardApi, id);
        const responseData = {
            deletedId: id
        };
        yield put(digitalShadeCardApiResponseSuccess({
            actionType: DigitalShadeCardActionTypes.DELETE_DIGITAL_SHADE_CARD,
            responseData
        }));
    } catch (error: any) {
        yield put(digitalShadeCardApiResponseError(DigitalShadeCardActionTypes.DELETE_DIGITAL_SHADE_CARD, error));

    }
}



export function* watchDigitalShadeCardList() {
    yield takeEvery(DigitalShadeCardActionTypes.DIGITAL_SHADE_CARD_LIST, digitalShadeCardList);
}

export function* watchAddDigitalShadeCard() {
    yield takeEvery(DigitalShadeCardActionTypes.ADD_DIGITAL_SHADE_CARD, addDigitalShadeCard);
}

export function* watchUpdateDigitalShadeCard() {
    yield takeEvery(DigitalShadeCardActionTypes.UPDATE_DIGITAL_SHADE_CARD, updateDigitalShadeCard);
}
export function* watchShowDigitalShadeCard() {
    yield takeEvery(DigitalShadeCardActionTypes.SHOW_DIGITAL_SHADE_CARD, showDigitalShadeCard);
}

export function* watchDeleteDigitalShadeCard() {
    yield takeEvery(DigitalShadeCardActionTypes.DELETE_DIGITAL_SHADE_CARD, deleteDigitalShadeCard);
}



function* digitalShadeCardSaga() {
    yield all([fork(watchDigitalShadeCardList), fork(watchAddDigitalShadeCard), fork(watchShowDigitalShadeCard), fork(watchUpdateDigitalShadeCard), fork(watchDeleteDigitalShadeCard)]);
}

export default digitalShadeCardSaga;
