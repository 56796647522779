// constants
import { ExperienceCenterActionTypes } from './constants';

export interface ExperienceCenterActionType {
    type:
    | ExperienceCenterActionTypes.API_RESPONSE_SUCCESS
    | ExperienceCenterActionTypes.API_RESPONSE_ERROR
    | ExperienceCenterActionTypes.EXPERIENCE_CENTER_LIST
    | ExperienceCenterActionTypes.ADD_EXPERIENCE_CENTER
    | ExperienceCenterActionTypes.UPDATE_EXPERIENCE_CENTER
    | ExperienceCenterActionTypes.SHOW_EXPERIENCE_CENTER
    | ExperienceCenterActionTypes.DELETE_EXPERIENCE_CENTER
    payload: {} | string;
}

interface FiltersData {
    status: string,
    sortBy: string,
    limit: number,
    page: number,
    search: string
};

interface FormData {
    status: string,
    id: string,
    productName: string,
    category: string,
    ci: string,
    productCode: string,
    contentOnColorant: string,
    lightFastness: string,
    image: string,
    heatStability: string,
    washingN: string,
    washingPES: string,
    washingPA: string,
    washingCO: string,
    sublimationN: string,
    sublimationPES: string,
    sublimationCO: string
};


interface ResponseData {
    actionType: string,
    responseData: any,
}


// common success
export const experienceCenterApiResponseSuccess = ({ actionType, responseData }: ResponseData): ExperienceCenterActionType => ({
    type: ExperienceCenterActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, responseData },
});
// common error
export const experienceCenterApiResponseError = (actionType: string, error: string): ExperienceCenterActionType => ({
    type: ExperienceCenterActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const createExperienceCenter = ({
    productName,
    category,
    ci,
    productCode,
    contentOnColorant,
    lightFastness,
    image,
    heatStability,
    washingN,
    washingPES,
    washingPA,
    washingCO,
    sublimationN,
    sublimationPES,
    sublimationCO,
    status,
}: FormData): ExperienceCenterActionType => ({
    type: ExperienceCenterActionTypes.ADD_EXPERIENCE_CENTER,
    payload: {
        productName,
        category,
        ci,
        productCode,
        contentOnColorant,
        lightFastness,
        image,
        heatStability,
        washingN,
        washingPES,
        washingPA,
        washingCO,
        sublimationN,
        sublimationPES,
        sublimationCO,
        status,
    },
});


export const experienceCenterList = ({
    status,
    sortBy,
    limit,
    page,
    search,
}: FiltersData): ExperienceCenterActionType => ({
    type: ExperienceCenterActionTypes.EXPERIENCE_CENTER_LIST,
    payload: {
        search,
        status,
        sortBy,
        limit,
        page,
    },
});

export const showExperienceCenter = (id: string): ExperienceCenterActionType => ({
    type: ExperienceCenterActionTypes.SHOW_EXPERIENCE_CENTER,
    payload: { id },
});

export const destroyExperienceCenter = (id: string): ExperienceCenterActionType => ({
    type: ExperienceCenterActionTypes.DELETE_EXPERIENCE_CENTER,
    payload: { id },
});

export const updateExperienceCenter = ({
    status,
    id,
    productName,
    category,
    ci,
    productCode,
    contentOnColorant,
    lightFastness,
    image,
    heatStability,
    washingN,
    washingPES,
    washingPA,
    washingCO,
    sublimationN,
    sublimationPES,
    sublimationCO
}: FormData): ExperienceCenterActionType => ({
    type: ExperienceCenterActionTypes.UPDATE_EXPERIENCE_CENTER,
    payload: {
        status,
        id,
        productName,
        category,
        ci,
        productCode,
        contentOnColorant,
        lightFastness,
        image,
        heatStability,
        washingN,
        washingPES,
        washingPA,
        washingCO,
        sublimationN,
        sublimationPES,
        sublimationCO,
    },
});



