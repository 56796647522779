import { APICore } from './apiCore';
const api = new APICore();

// jobTitles
function jobTitles(params: {
    status: string,
    search: string,
    sortBy: string,
    limit: number,
    page: number,
}) {
    const baseUrl = `/job-titles`;
    return api.get(`${baseUrl}`, params);

}
function addJobTitles(params: {
    status: string,
    title: string
}) {
    const baseUrl = `/job-titles`;
    return api.create(`${baseUrl}`, params);
}

function updateJobTitles(params: {
    status: string,
    title: string
}, id: string) {
    const baseUrl = `/job-titles/${id}`;
    return api.updatePatch(`${baseUrl}`, params);

}

function deleteJobTitles(id: string) {
    const baseUrl = `/job-titles/${id}`;
    return api.delete(`${baseUrl}`);
}

function showJobTitles(params: {}, id: string) {
    const baseUrl = `/job-titles/${id}`;
    return api.get(`${baseUrl}`, params);
}

export {
    jobTitles,
    addJobTitles,
    updateJobTitles,
    deleteJobTitles,
    showJobTitles
};
