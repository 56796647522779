// constants
import { DigitalShadeCardActionTypes } from './constants';

const INIT_STATE = {
    digitalShadeCards: [],
    digitalShadeCard: {},
    page: 1,
    limit: 10,
    totalPages: 0,
    totalResults: 0,
    loading: false,
    hasErrors: false,
    submitted: false,
    isDeleted: false,
};

interface DigitalShadeCardActionType {
    type:
    | DigitalShadeCardActionTypes.API_RESPONSE_SUCCESS
    | DigitalShadeCardActionTypes.API_RESPONSE_ERROR
    | DigitalShadeCardActionTypes.DIGITAL_SHADE_CARD_LIST
    | DigitalShadeCardActionTypes.ADD_DIGITAL_SHADE_CARD
    | DigitalShadeCardActionTypes.SHOW_DIGITAL_SHADE_CARD
    | DigitalShadeCardActionTypes.UPDATE_DIGITAL_SHADE_CARD

    payload: {
        actionType?: string;
        responseData?: any;
        error?: any;
        validationError?: { error: string; key: string; }[];
        hasErrors: boolean,
        submitted: boolean,
        isDeleted: boolean,
    };
}

interface State {
    digitalShadeCards: any;
    digitalShadeCard: any;
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
}

const DigitalShadeCard = (state: State = INIT_STATE, action: DigitalShadeCardActionType): any => {
    switch (action.type) {
        case DigitalShadeCardActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload?.actionType) {  
                case DigitalShadeCardActionTypes.DIGITAL_SHADE_CARD_LIST: {
                    return {
                        ...state,
                        digitalShadeCards: action.payload?.responseData?.data,
                        page: action.payload?.responseData?.page,
                        limit: action.payload?.responseData?.limit,
                        totalPages: action.payload?.responseData?.totalPages,
                        totalResults: action.payload?.responseData?.totalResults,
                        loading: true,
                        submitted: false,
                    };
                }
                case DigitalShadeCardActionTypes.ADD_DIGITAL_SHADE_CARD: {
                    return {
                        ...state,
                        digitalShadeCards: [...state.digitalShadeCards, action.payload?.responseData?.data],
                        totalResults: (action.payload?.responseData?.totalResults ? (action.payload?.responseData?.totalResults + 1) : 0),
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case DigitalShadeCardActionTypes.SHOW_DIGITAL_SHADE_CARD: {
                    return {
                        ...state,
                        digitalShadeCard: action.payload?.responseData?.data,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case DigitalShadeCardActionTypes.UPDATE_DIGITAL_SHADE_CARD: {
                    const updatedDigitalShadeCard = action.payload?.responseData?.data;
                    const allSubs = state.digitalShadeCards;
                    const updatedSubs= allSubs.map((cat: any) => cat.id === updatedDigitalShadeCard.id ? updatedDigitalShadeCard : cat);
                    return {
                        ...state,
                        digitalShadeCards: updatedSubs,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case DigitalShadeCardActionTypes.DELETE_DIGITAL_SHADE_CARD: {
                    const deletedId = action.payload?.responseData?.deletedId;
                    const allSubs = state.digitalShadeCards;
                    const updatedSubs = allSubs.filter((cat: any) => cat.id !== deletedId);
                    return {
                        ...state,
                        digitalShadeCards: updatedSubs,
                        loading: true,
                        hasErrors: false,
                        submitted: false,
                        isDeleted: true,
                    };
                }
                default:
                    return { ...state };
            }

        case DigitalShadeCardActionTypes.API_RESPONSE_ERROR:
            switch (action.payload?.actionType) {
                case DigitalShadeCardActionTypes.DIGITAL_SHADE_CARD_LIST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: false,
                    };
                }
                case DigitalShadeCardActionTypes.ADD_DIGITAL_SHADE_CARD: {
                    return {
                        ...state,
                        error: action.payload?.error,
                        validationError: action.payload?.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case DigitalShadeCardActionTypes.SHOW_DIGITAL_SHADE_CARD: {
                    return {
                        ...state,
                        error: action.payload?.error,
                        validationError: action.payload?.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case DigitalShadeCardActionTypes.UPDATE_DIGITAL_SHADE_CARD: {
                    return {
                        ...state,
                        error: action.payload?.error,
                        validationError: action.payload?.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default DigitalShadeCard;
