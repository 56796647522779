export enum PrivateDigitalShadeCardActionTypes {
    API_RESPONSE_SUCCESS = '@@privateDigitalShadeCard/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@privateDigitalShadeCard/API_RESPONSE_ERROR',
    PRIVATE_DIGITAL_SHADE_CARD_LIST = '@@privateDigitalShadeCard/PRIVATE_DIGITAL_SHADE_CARD_LIST',
    ADD_PRIVATE_DIGITAL_SHADE_CARD = '@@privateDigitalShadeCard/ADD_PRIVATE_DIGITAL_SHADE_CARD',
    SHOW_PRIVATE_DIGITAL_SHADE_CARD = '@@privateDigitalShadeCard/SHOW_PRIVATE_DIGITAL_SHADE_CARD',
    UPDATE_PRIVATE_DIGITAL_SHADE_CARD = '@@privateDigitalShadeCard/UPDATE_PRIVATE_DIGITAL_SHADE_CARD',
    DELETE_PRIVATE_DIGITAL_SHADE_CARD = '@@privateDigitalShadeCard/DELETE_PRIVATE_DIGITAL_SHADE_CARD',
}

