import { PrivateDigitalShadeCardActionTypes } from './constants';

const INIT_STATE = {
    privateDigitalShadeCards: [],
    privateDigitalShadeCard: {},
    page: 1,
    limit: 10,
    totalPages: 0,
    totalResults: 0,
    loading: false,
    hasErrors: false,
    submitted: false,
    isDeleted: false,
};

interface PrivateDigitalShadeCardActionType {
    type:
    | PrivateDigitalShadeCardActionTypes.API_RESPONSE_SUCCESS
    | PrivateDigitalShadeCardActionTypes.API_RESPONSE_ERROR
    | PrivateDigitalShadeCardActionTypes.PRIVATE_DIGITAL_SHADE_CARD_LIST
    | PrivateDigitalShadeCardActionTypes.ADD_PRIVATE_DIGITAL_SHADE_CARD
    | PrivateDigitalShadeCardActionTypes.SHOW_PRIVATE_DIGITAL_SHADE_CARD
    | PrivateDigitalShadeCardActionTypes.UPDATE_PRIVATE_DIGITAL_SHADE_CARD

    payload: {
        actionType?: string;
        responseData?: any;
        error?: any;
        validationError?: { error: string; key: string; }[];
        hasErrors: boolean,
        submitted: boolean,
        isDeleted: boolean,
    };
}

interface State {
    privateDigitalShadeCards: any;
    privateDigitalShadeCard: any;
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
}

const PrivateDigitalShadeCard = (state: State = INIT_STATE, action: PrivateDigitalShadeCardActionType): any => {
    switch (action.type) {
        case PrivateDigitalShadeCardActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload?.actionType) {  
                case PrivateDigitalShadeCardActionTypes.PRIVATE_DIGITAL_SHADE_CARD_LIST: {
                    return {
                        ...state,
                        privateDigitalShadeCards: action.payload?.responseData?.data,
                        page: action.payload?.responseData?.page,
                        limit: action.payload?.responseData?.limit,
                        totalPages: action.payload?.responseData?.totalPages,
                        totalResults: action.payload?.responseData?.totalResults,
                        loading: true,
                        submitted: false,
                    };
                }
                case PrivateDigitalShadeCardActionTypes.ADD_PRIVATE_DIGITAL_SHADE_CARD: {
                    return {
                        ...state,
                        privateDigitalShadeCards: [...state.privateDigitalShadeCards, action.payload?.responseData?.data],
                        totalResults: (action.payload?.responseData?.totalResults ? (action.payload?.responseData?.totalResults + 1) : 0),
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case PrivateDigitalShadeCardActionTypes.SHOW_PRIVATE_DIGITAL_SHADE_CARD: {
                    return {
                        ...state,
                        privateDigitalShadeCard: action.payload?.responseData?.data,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case PrivateDigitalShadeCardActionTypes.UPDATE_PRIVATE_DIGITAL_SHADE_CARD: {
                    const updatedPrivateDigitalShadeCard = action.payload?.responseData?.data;
                    const allSubs = state.privateDigitalShadeCards;
                    const updatedSubs= allSubs.map((cat: any) => cat.id === updatedPrivateDigitalShadeCard.id ? updatedPrivateDigitalShadeCard : cat);
                    return {
                        ...state,
                        privateDigitalShadeCards: updatedSubs,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case PrivateDigitalShadeCardActionTypes.DELETE_PRIVATE_DIGITAL_SHADE_CARD: {
                    const deletedId = action.payload?.responseData?.deletedId;
                    const allSubs = state.privateDigitalShadeCards;
                    const updatedSubs = allSubs.filter((cat: any) => cat.id !== deletedId);
                    return {
                        ...state,
                        privateDigitalShadeCards: updatedSubs,
                        loading: true,
                        hasErrors: false,
                        submitted: false,
                        isDeleted: true,
                    };
                }
                default:
                    return { ...state };
            }

        case PrivateDigitalShadeCardActionTypes.API_RESPONSE_ERROR:
            switch (action.payload?.actionType) {
                case PrivateDigitalShadeCardActionTypes.PRIVATE_DIGITAL_SHADE_CARD_LIST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: false,
                    };
                }
                case PrivateDigitalShadeCardActionTypes.ADD_PRIVATE_DIGITAL_SHADE_CARD: {
                    return {
                        ...state,
                        error: action.payload?.error,
                        validationError: action.payload?.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case PrivateDigitalShadeCardActionTypes.SHOW_PRIVATE_DIGITAL_SHADE_CARD: {
                    return {
                        ...state,
                        error: action.payload?.error,
                        validationError: action.payload?.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case PrivateDigitalShadeCardActionTypes.UPDATE_PRIVATE_DIGITAL_SHADE_CARD: {
                    return {
                        ...state,
                        error: action.payload?.error,
                        validationError: action.payload?.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default PrivateDigitalShadeCard;
