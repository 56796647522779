export enum ExperienceCenterActionTypes {
    API_RESPONSE_SUCCESS = '@@experienceCenter/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@experienceCenter/API_RESPONSE_ERROR',
    EXPERIENCE_CENTER_LIST = '@@experienceCenter/EXPERIENCE_CENTER_LIST',
    ADD_EXPERIENCE_CENTER = '@@experienceCenter/ADD_EXPERIENCE_CENTER',
    SHOW_EXPERIENCE_CENTER = '@@experienceCenter/SHOW_EXPERIENCE_CENTER',
    UPDATE_EXPERIENCE_CENTER = '@@experienceCenter/UPDATE_EXPERIENCE_CENTER',
    DELETE_EXPERIENCE_CENTER = '@@experienceCenter/DELETE_EXPERIENCE_CENTER',
}

