import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import {
    investorRelations as investorRelationsApi,
    addInvestorRelation as addInvestorRelationApi,
    updateInvestorRelation as updateInvestorRelationApi,
    showInvestorRelation as showInvestorRelationApi,
    deleteInvestorRelation as deleteInvestorRelationApi,
} from '../../helpers';

// actions
import { investorRelationApiResponseSuccess, investorRelationApiResponseError } from './actions';

// constants
import { InvestorRelationActionTypes } from './constants';

interface InvestorRelationData {
    payload: {
        search: string;
        description: string;
        sortBy: string,
        limit: number,
        page: number,
        //
        id: string,
        status: string,
        title: string,

        order: number,
        tabs: Array<any>,
        relationType: string,
    };
    type: string;
}

/**
 * InvestorRelation list
 */
function* investorRelationList({ payload: {
    search,
    status,
    sortBy,
    limit,
    page
} }: InvestorRelationData): SagaIterator {
    try {

        const response = yield call(investorRelationsApi, {
            search,
            status,
            sortBy,
            limit,
            page
        });
        const responseData = {
            data: response.data.results,
            page: response.data.page,
            limit: response.data.limit,
            totalPages: response.data.totalPages,
            totalResults: response.data.totalResults,
        }
        yield put(investorRelationApiResponseSuccess({
            actionType: InvestorRelationActionTypes.INVESTOR_RELATION_LIST,
            responseData
        }));
    } catch (error: any) {
        yield put(investorRelationApiResponseError(InvestorRelationActionTypes.INVESTOR_RELATION_LIST, error));

    }
}

/**
 * Add InvestorRelation
 */
function* addInvestorRelation({ payload: {
    title,
    order,
    status,
    tabs,
    relationType } }: InvestorRelationData): SagaIterator {
    try {

        const response = yield call(addInvestorRelationApi, {
            title,
            order,
            status,
            relationType,
            tabs
        });
        const responseData = {
            data: response.data.data,
        }
        yield put(investorRelationApiResponseSuccess({
            actionType: InvestorRelationActionTypes.ADD_INVESTOR_RELATION,
            responseData
        }

        ));
    } catch (error: any) {
        yield put(investorRelationApiResponseError(InvestorRelationActionTypes.ADD_INVESTOR_RELATION, error));

    }
}

/**
 * Show User
 */
function* showInvestorRelation({ payload: {
    id,
} }: InvestorRelationData): SagaIterator {
    try {
        const response = yield call(showInvestorRelationApi, {}, id);
        const responseData = {
            data: response.data,
        }
        yield put(investorRelationApiResponseSuccess({
            actionType: InvestorRelationActionTypes.SHOW_INVESTOR_RELATION,
            responseData
        }
        ));
    } catch (error: any) {
        yield put(investorRelationApiResponseError(InvestorRelationActionTypes.SHOW_INVESTOR_RELATION, error));

    }
}

/**
 * Update InvestorRelation
 */
function* updateInvestorRelation({ payload: {
    id,
    title,
    order,
    status,
    relationType,
    tabs } }: InvestorRelationData): SagaIterator {
    try {
        const response = yield call(updateInvestorRelationApi, {
            status,
            title,
            order,
            relationType,
            tabs,
        }, id);
        const responseData = {
            data: response.data.data,
        }
        yield put(investorRelationApiResponseSuccess({
            actionType: InvestorRelationActionTypes.UPDATE_INVESTOR_RELATION,
            responseData
        }
        ));
    } catch (error: any) {
        yield put(investorRelationApiResponseError(InvestorRelationActionTypes.UPDATE_INVESTOR_RELATION, error));

    }
}
/**
 * Delete InvestorRelation
 */
function* deleteInvestorRelation({ payload: { id } }: InvestorRelationData): SagaIterator {
    try {
        yield call(deleteInvestorRelationApi, id);
        const responseData = {
            deletedId: id
        };
        yield put(investorRelationApiResponseSuccess({
            actionType: InvestorRelationActionTypes.DELETE_INVESTOR_RELATION,
            responseData
        }));
    } catch (error: any) {
        yield put(investorRelationApiResponseError(InvestorRelationActionTypes.DELETE_INVESTOR_RELATION, error));

    }
}



export function* watchInvestorRelationList() {
    yield takeEvery(InvestorRelationActionTypes.INVESTOR_RELATION_LIST, investorRelationList);
}

export function* watchAddInvestorRelation() {
    yield takeEvery(InvestorRelationActionTypes.ADD_INVESTOR_RELATION, addInvestorRelation);
}

export function* watchUpdateInvestorRelation() {
    yield takeEvery(InvestorRelationActionTypes.UPDATE_INVESTOR_RELATION, updateInvestorRelation);
}
export function* watchShowInvestorRelation() {
    yield takeEvery(InvestorRelationActionTypes.SHOW_INVESTOR_RELATION, showInvestorRelation);
}

export function* watchDeleteInvestorRelation() {
    yield takeEvery(InvestorRelationActionTypes.DELETE_INVESTOR_RELATION, deleteInvestorRelation);
}



function* investorRelationSaga() {
    yield all([fork(watchInvestorRelationList), fork(watchAddInvestorRelation), fork(watchShowInvestorRelation), fork(watchUpdateInvestorRelation), fork(watchDeleteInvestorRelation)]);
}

export default investorRelationSaga;
