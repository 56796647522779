// constants
import { ChatFormSetupActionTypes } from './constants';

const INIT_STATE = {
    chatFormSetups: [],
    chatFormSetup: {},
    page: 1,
    limit: 10,
    totalPages: 0,
    totalResults: 0,
    loading: false,
    hasErrors: false,
    submitted: false,
    isDeleted: false,
};

interface ChatFormSetupActionType {
    type:
    | ChatFormSetupActionTypes.API_RESPONSE_SUCCESS
    | ChatFormSetupActionTypes.API_RESPONSE_ERROR
    | ChatFormSetupActionTypes.CHAT_SETUP_LIST
    | ChatFormSetupActionTypes.ADD_CHAT_SETUP
    | ChatFormSetupActionTypes.SHOW_CHAT_SETUP
    | ChatFormSetupActionTypes.UPDATE_CHAT_SETUP

    payload: {
        actionType?: string;
        responseData?: any;
        error?: any;
        validationError?: { error: string; key: string; }[];
        hasErrors: boolean,
        submitted: boolean,
        isDeleted: boolean,
    };
}

interface State {
    chatFormSetups: any;
    chatFormSetup: any;
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
}

const ChatFormSetup = (state: State = INIT_STATE, action: ChatFormSetupActionType): any => {
    switch (action.type) {
        case ChatFormSetupActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload?.actionType) {
                case ChatFormSetupActionTypes.CHAT_SETUP_LIST: {
                    return {
                        ...state,
                        chatFormSetups: action.payload?.responseData?.data,
                        loading: true,
                        submitted: false,
                    };
                }
                case ChatFormSetupActionTypes.ADD_CHAT_SETUP: {                    
                    return {
                        ...state,
                        chatFormSetups: action.payload?.responseData?.data,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case ChatFormSetupActionTypes.SHOW_CHAT_SETUP: {
                    return {
                        ...state,
                        chatFormSetups: action.payload?.responseData?.data,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case ChatFormSetupActionTypes.UPDATE_CHAT_SETUP: {
                    return {
                        ...state,
                        chatFormSetups: action.payload?.responseData?.data,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case ChatFormSetupActionTypes.DELETE_CHAT_SETUP: {
                    return {
                        ...state,
                        chatFormSetups: action.payload?.responseData?.data,
                        loading: true,
                        hasErrors: false,
                        submitted: false,
                        isDeleted: true,
                    };
                }
                default:
                    return { ...state };
            }

        case ChatFormSetupActionTypes.API_RESPONSE_ERROR:
            switch (action.payload?.actionType) {
                case ChatFormSetupActionTypes.CHAT_SETUP_LIST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: false,
                    };
                }
                case ChatFormSetupActionTypes.ADD_CHAT_SETUP: {
                    return {
                        ...state,
                        error: action.payload?.error,
                        validationError: action.payload?.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case ChatFormSetupActionTypes.SHOW_CHAT_SETUP: {
                    return {
                        ...state,
                        error: action.payload?.error,
                        validationError: action.payload?.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case ChatFormSetupActionTypes.UPDATE_CHAT_SETUP: {
                    return {
                        ...state,
                        error: action.payload?.error,
                        validationError: action.payload?.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default ChatFormSetup;
