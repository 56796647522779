import { APICore } from './apiCore';
const api = new APICore();

// newsAndEvent
function newsAndEvents(params: {
    status: string,
    search: string,
    sortBy: string,
    limit: number,
    page: number,
}) {
    const baseUrl = `/news-and-events`;
    return api.get(`${baseUrl}`, params);

}
function addNewsAndEvent(params: {
    title: string,
    image: string,
    shortContent: string
    content: string,
    status: string,
}) {
    const baseUrl = `/news-and-events`;
    return api.create(`${baseUrl}`, params);
}

function updateNewsAndEvent(params: {
    status: string,
    title: string,
    image: string,
    shortContent: string
    content: string
}, id: string) {
    const baseUrl = `/news-and-events/${id}`;
    return api.updatePatch(`${baseUrl}`, params);

}

function deleteNewsAndEvent(id: string) {
    const baseUrl = `/news-and-events/${id}`;
    return api.delete(`${baseUrl}`);
}

function showNewsAndEvent(params: {}, id: string) {
    const baseUrl = `/news-and-events/${id}`;
    return api.get(`${baseUrl}`, params);
}

export {
    newsAndEvents,
    addNewsAndEvent,
    updateNewsAndEvent,
    deleteNewsAndEvent,
    showNewsAndEvent
};
