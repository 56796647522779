import { APICore } from './apiCore';
const api = new APICore();

// experienceCenter
function experienceCenters(params: {
    status: string,
    search: string,
    sortBy: string,
    limit: number,
    page: number,
}) {
    const baseUrl = `/experience-center`;
    return api.get(`${baseUrl}`, params);

}
function addExperienceCenter(params: {
    productName: string,
    category: string,
    ci: string,
    productCode: string,
    contentOnColorant: string,
    lightFastness: string,
    image: string,
    heatStability: string,
    washingN: string,
    washingPES: string,
    washingPA: string,
    washingCO: string,
    sublimationN: string,
    sublimationPES: string,
    sublimationCO: string
    status: string
}) {
    const baseUrl = `/experience-center`;
    return api.create(`${baseUrl}`, params);
}

function updateExperienceCenter(params: {
    status: string,
    productName: string,
    category: string,
    ci: string,
    productCode: string,
    contentOnColorant: string,
    lightFastness: string,
    image: string,
    heatStability: string,
    washingN: string,
    washingPES: string,
    washingPA: string,
    washingCO: string,
    sublimationN: string,
    sublimationPES: string,
    sublimationCO: string
}, id: string) {
    const baseUrl = `/experience-center/${id}`;
    return api.updatePatch(`${baseUrl}`, params);

}

function deleteExperienceCenter(id: string) {
    const baseUrl = `/experience-center/${id}`;
    return api.delete(`${baseUrl}`);
}

function showExperienceCenter(params: {}, id: string) {
    const baseUrl = `/experience-center/${id}`;
    return api.get(`${baseUrl}`, params);
}

export {
    experienceCenters,
    addExperienceCenter,
    updateExperienceCenter,
    deleteExperienceCenter,
    showExperienceCenter
};
