// constants
import { JobTitlesActionTypes } from './constants';

const INIT_STATE = {
    jobTitles: [],
    JobTitle: {},
    page: 1,
    limit: 10,
    totalPages: 0,
    totalResults: 0,
    loading: false,
    hasErrors: false,
    submitted: false,
    isDeleted: false,
};

interface JobTitlesActionType {
    type:
    | JobTitlesActionTypes.API_RESPONSE_SUCCESS
    | JobTitlesActionTypes.API_RESPONSE_ERROR
    | JobTitlesActionTypes.JOB_TITLES_LIST
    | JobTitlesActionTypes.ADD_JOB_TITLES
    | JobTitlesActionTypes.SHOW_JOB_TITLES
    | JobTitlesActionTypes.UPDATE_JOB_TITLES

    payload: {
        actionType?: string;
        responseData?: any;
        error?: any;
        validationError?: { error: string; key: string; }[];
        hasErrors: boolean,
        submitted: boolean,
        isDeleted: boolean,
    };
}

interface State {
    jobTitles: any;
    JobTitle: any;
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
}

const JobTitles = (state: State = INIT_STATE, action: JobTitlesActionType): any => {
    switch (action.type) {
        case JobTitlesActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload?.actionType) {  
                case JobTitlesActionTypes.JOB_TITLES_LIST: {
                    return {
                        ...state,
                        jobTitles: action.payload?.responseData?.data,
                        page: action.payload?.responseData?.page,
                        limit: action.payload?.responseData?.limit,
                        totalPages: action.payload?.responseData?.totalPages,
                        totalResults: action.payload?.responseData?.totalResults,
                        loading: true,
                        submitted: false,
                    };
                }
                case JobTitlesActionTypes.ADD_JOB_TITLES: {
                    return {
                        ...state,
                        jobTitles: [...state.jobTitles, action.payload?.responseData?.data],
                        totalResults: (action.payload?.responseData?.totalResults ? (action.payload?.responseData?.totalResults + 1) : 0),
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case JobTitlesActionTypes.SHOW_JOB_TITLES: {
                    return {
                        ...state,
                        JobTitle: action.payload?.responseData?.data,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case JobTitlesActionTypes.UPDATE_JOB_TITLES: {
                    const updatedJobTitles = action.payload?.responseData?.data;
                    const allSubs = state.jobTitles;
                    const updatedSubs= allSubs.map((cat: any) => cat.id === updatedJobTitles.id ? updatedJobTitles : cat);
                    return {
                        ...state,
                        jobTitles: updatedSubs,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case JobTitlesActionTypes.DELETE_JOB_TITLES: {
                    const deletedId = action.payload?.responseData?.deletedId;
                    const allSubs = state.jobTitles;
                    const updatedSubs = allSubs.filter((cat: any) => cat.id !== deletedId);
                    return {
                        ...state,
                        jobTitles: updatedSubs,
                        loading: true,
                        hasErrors: false,
                        submitted: false,
                        isDeleted: true,
                    };
                }
                default:
                    return { ...state };
            }

        case JobTitlesActionTypes.API_RESPONSE_ERROR:
            switch (action.payload?.actionType) {
                case JobTitlesActionTypes.JOB_TITLES_LIST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: false,
                    };
                }
                case JobTitlesActionTypes.ADD_JOB_TITLES: {
                    return {
                        ...state,
                        error: action.payload?.error,
                        validationError: action.payload?.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case JobTitlesActionTypes.SHOW_JOB_TITLES: {
                    return {
                        ...state,
                        error: action.payload?.error,
                        validationError: action.payload?.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case JobTitlesActionTypes.UPDATE_JOB_TITLES: {
                    return {
                        ...state,
                        error: action.payload?.error,
                        validationError: action.payload?.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default JobTitles;
