import { APICore } from './apiCore';
const api = new APICore();

// chatFormSetup
function chatFormSetups(params: {}) {
    const baseUrl = `/chat-setup`;
    return api.get(`${baseUrl}`, params);

}
function addChatFormSetup(params: {
    question: string,
    type: string,
    parentId: string
}) {
    const baseUrl = `/chat-setup`;
    return api.create(`${baseUrl}`, params);
}

function updateChatFormSetup(params: {
    question: string,
    type: string,
    parentId: string
}, _id: string) {
    const baseUrl = `/chat-setup/${_id}`;
    return api.updatePatch(`${baseUrl}`, params);

}

function deleteChatFormSetup(_id: string) {
    const baseUrl = `/chat-setup/${_id}`;
    return api.delete(`${baseUrl}`);
}

function showChatFormSetup(params: {}, _id: string) {
    const baseUrl = `/chat-setup/${_id}`;
    return api.get(`${baseUrl}`, params);
}

export {
    chatFormSetups,
    addChatFormSetup,
    updateChatFormSetup,
    deleteChatFormSetup,
    showChatFormSetup
};
