// constants
import { CreateYourOwnActionTypes } from './constants';

const INIT_STATE = {
    createYourOwn: [],
    jobTitle: {},
    page: 1,
    limit: 10,
    totalPages: 0,
    totalResults: 0,
    loading: false,
    hasErrors: false,
    submitted: false,
    isDeleted: false,
};

interface CreateYourOwnActionType {
    type:
    | CreateYourOwnActionTypes.API_RESPONSE_SUCCESS
    | CreateYourOwnActionTypes.API_RESPONSE_ERROR
    | CreateYourOwnActionTypes.CREATE_YOUR_OWN_LIST

    payload: {
        actionType?: string;
        responseData?: any;
        error?: any;
        validationError?: { error: string; key: string; }[];
        hasErrors: boolean,
        submitted: boolean,
        isDeleted: boolean,
    };
}

interface State {
    createYourOwn: any;
    jobTitle: any;
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
}

const CreateYourOwn = (state: State = INIT_STATE, action: CreateYourOwnActionType): any => {
    switch (action.type) {
        case CreateYourOwnActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload?.actionType) {
                case CreateYourOwnActionTypes.CREATE_YOUR_OWN_LIST: {
                    return {
                        ...state,
                        createYourOwn: action.payload?.responseData?.data,
                        page: action.payload?.responseData?.page,
                        limit: action.payload?.responseData?.limit,
                        totalPages: action.payload?.responseData?.totalPages,
                        totalResults: action.payload?.responseData?.totalResults,
                        loading: true,
                        submitted: false,
                    };
                }

                default:
                    return { ...state };
            }

        case CreateYourOwnActionTypes.API_RESPONSE_ERROR:
            switch (action.payload?.actionType) {
                case CreateYourOwnActionTypes.CREATE_YOUR_OWN_LIST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: false,
                    };
                }

                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default CreateYourOwn;
