// constants
import { JobSubmissionsActionTypes } from './constants';

export interface JobSubmissionsActionType {
    type:
    | JobSubmissionsActionTypes.API_RESPONSE_SUCCESS
    | JobSubmissionsActionTypes.API_RESPONSE_ERROR
    | JobSubmissionsActionTypes.JOB_SUBMISSIONS_LIST
    payload: {} | string;
}

interface FiltersData {
    sortBy: string,
    limit: number,
    page: number,
    search: string
};

interface ResponseData {
    actionType: string,
    responseData: any,
}


// common success
export const jobSubmissionsApiResponseSuccess = ({ actionType, responseData }: ResponseData): JobSubmissionsActionType => ({
    type: JobSubmissionsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, responseData },
});
// common error
export const jobSubmissionsApiResponseError = (actionType: string, error: string): JobSubmissionsActionType => ({
    type: JobSubmissionsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const jobSubmissionsList = ({
    sortBy,
    limit,
    page,
    search,
}: FiltersData): JobSubmissionsActionType => ({
    type: JobSubmissionsActionTypes.JOB_SUBMISSIONS_LIST,
    payload: {
        search,
        sortBy,
        limit,
        page,
    },
});




