// constants
import { UserActionTypes } from './constants';

export interface UserActionType {
    type:
    | UserActionTypes.API_RESPONSE_SUCCESS
    | UserActionTypes.API_RESPONSE_ERROR
    | UserActionTypes.USER_LIST
    | UserActionTypes.ADD_USER
    | UserActionTypes.UPDATE_USER
    | UserActionTypes.SHOW_USER
    | UserActionTypes.DELETE_USER
    payload: {} | string;
}


// common success
export const userApiResponseSuccess = (actionType: string, data: any, page: number = 0, limit: number = 0, totalPages: number = 0, totalResults: number = 0): UserActionType => ({
    type: UserActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data, page, limit, totalPages, totalResults },
});
// common error
export const userApiResponseError = (actionType: string, error: string): UserActionType => ({
    type: UserActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

interface FiltersData {
    id: string,
    phone: string,
    sortBy: string,
    limit: number,
    page: number,
};

export const getUser = ({
    phone,
    sortBy,
    limit,
    page,
}: FiltersData): UserActionType => ({
    type: UserActionTypes.USER_LIST,
    payload: {
        phone,
        sortBy,
        limit,
        page,
    },
});
export const showUser = ({
    id,
    sortBy,
    limit,
    page,
}: FiltersData): UserActionType => ({
    type: UserActionTypes.SHOW_USER,
    payload: {
        id,
        sortBy,
        limit,
        page,
    },
});

export const deleteUser = (id: string): UserActionType => ({
    type: UserActionTypes.DELETE_USER,
    payload: { id },
});

export const updateUser = (
    id: string,
    status: string,
): UserActionType => ({
    type: UserActionTypes.UPDATE_USER,
    payload: {
        id,
        status,
    },
});






