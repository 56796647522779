// constants
import { PrivateDigitalShadeCardActionTypes } from './constants';

export interface PrivateDigitalShadeCardActionType {
    type:
    | PrivateDigitalShadeCardActionTypes.API_RESPONSE_SUCCESS
    | PrivateDigitalShadeCardActionTypes.API_RESPONSE_ERROR
    | PrivateDigitalShadeCardActionTypes.PRIVATE_DIGITAL_SHADE_CARD_LIST
    | PrivateDigitalShadeCardActionTypes.ADD_PRIVATE_DIGITAL_SHADE_CARD
    | PrivateDigitalShadeCardActionTypes.UPDATE_PRIVATE_DIGITAL_SHADE_CARD
    | PrivateDigitalShadeCardActionTypes.SHOW_PRIVATE_DIGITAL_SHADE_CARD
    | PrivateDigitalShadeCardActionTypes.DELETE_PRIVATE_DIGITAL_SHADE_CARD
    payload: {} | string;
}

interface FiltersData {
    status: string,
    sortBy: string,
    limit: number,
    page: number,
    search: string
};

interface FormData {
    id: string,
    status: string,
    title: string,
    email: string,
    phone: string,
    expiry: string,
    bookSize: string,
    password: string,
    companyName: string,
    images: Array<any>,
    pdf: string,
};


interface ResponseData {
    actionType: string,
    responseData: any,
}


// common success
export const privateDigitalShadeCardApiResponseSuccess = ({ actionType, responseData }: ResponseData): PrivateDigitalShadeCardActionType => ({
    type: PrivateDigitalShadeCardActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, responseData },
});
// common error
export const privateDigitalShadeCardApiResponseError = (actionType: string, error: string): PrivateDigitalShadeCardActionType => ({
    type: PrivateDigitalShadeCardActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const createPrivateDigitalShadeCard = ({
    title,
    bookSize,
    email,
    phone,
    expiry,
    password,
    companyName,
    images,
    status,
    pdf
}: FormData): PrivateDigitalShadeCardActionType => ({
    type: PrivateDigitalShadeCardActionTypes.ADD_PRIVATE_DIGITAL_SHADE_CARD,
    payload: {
        title,
        bookSize,
        email,
        phone,
        expiry,
        password,
        companyName,
        status,
        images,
        pdf
    },
});


export const privateDigitalShadeCardList = ({
    status,
    sortBy,
    limit,
    page,
    search,
}: FiltersData): PrivateDigitalShadeCardActionType => ({
    type: PrivateDigitalShadeCardActionTypes.PRIVATE_DIGITAL_SHADE_CARD_LIST,
    payload: {
        search,
        status,
        sortBy,
        limit,
        page,
    },
});

export const showPrivateDigitalShadeCard = (id: string): PrivateDigitalShadeCardActionType => ({
    type: PrivateDigitalShadeCardActionTypes.SHOW_PRIVATE_DIGITAL_SHADE_CARD,
    payload: { id },
});

export const destroyPrivateDigitalShadeCard = (id: string): PrivateDigitalShadeCardActionType => ({
    type: PrivateDigitalShadeCardActionTypes.DELETE_PRIVATE_DIGITAL_SHADE_CARD,
    payload: { id },
});

export const updatePrivateDigitalShadeCard = ({
    id,
    status,
    title,
    bookSize,
    email,
    phone,
    expiry,
    password,
    companyName,
    images,
    pdf,
}: FormData): PrivateDigitalShadeCardActionType => ({
    type: PrivateDigitalShadeCardActionTypes.UPDATE_PRIVATE_DIGITAL_SHADE_CARD,
    payload: {
        id,
        status,
        title,
        bookSize,
        email,
        phone,
        expiry,
        password,
        companyName,
        images,
        pdf,
    },
});




