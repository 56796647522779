export enum NewsAndEventActionTypes {
    API_RESPONSE_SUCCESS = '@@newsAndEvent/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@newsAndEvent/API_RESPONSE_ERROR',
    NEWS_AND_EVENT_LIST = '@@newsAndEvent/NEWS_AND_EVENT_LIST',
    ADD_NEWS_AND_EVENT = '@@newsAndEvent/ADD_NEWS_AND_EVENT',
    SHOW_NEWS_AND_EVENT = '@@newsAndEvent/SHOW_NEWS_AND_EVENT',
    UPDATE_NEWS_AND_EVENT = '@@newsAndEvent/UPDATE_NEWS_AND_EVENT',
    DELETE_NEWS_AND_EVENT = '@@newsAndEvent/DELETE_NEWS_AND_EVENT',
}

