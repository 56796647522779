import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import {
    users as usersApi,
    showUser as showUserApi,
} from '../../helpers';

// actions
import { userApiResponseSuccess, userApiResponseError } from './actions';

// constants
import { UserActionTypes } from './constants';

interface UserData {
    payload: {
        id: string,
        phone: string,
        hasSubscribed: boolean,
        sortBy: string,
        page: number,
        limit: number,
    };
    type: string;
}

/**
 * User list
 */
function* userList({ payload: {
    phone,
    sortBy,
    limit,
    page
} }: UserData): SagaIterator {
    try {

        const response = yield call(usersApi, {
            phone,
            sortBy,
            limit,
            page
        });
        yield put(userApiResponseSuccess(
            UserActionTypes.USER_LIST,
            response.data.results,
            response.data.page,
            response.data.limit,
            response.data.totalPages,
            response.data.totalResults,
        ));
    } catch (error: any) {
        yield put(userApiResponseError(UserActionTypes.USER_LIST, error));

    }
}

/**
 * Show User
 */
function* showUser({ payload: {
    id,
    sortBy,
    limit,
    page
} }: UserData): SagaIterator {
    try {
        const response = yield call(showUserApi, {
            sortBy,
            limit,
            page
        },id);
        yield put(userApiResponseSuccess(
            UserActionTypes.SHOW_USER,
            response.data,
            1,
            10,
            1,
            1,
        ));
    } catch (error: any) {
        yield put(userApiResponseError(UserActionTypes.SHOW_USER, error));

    }
}




export function* watchUserList() {
    yield takeEvery(UserActionTypes.USER_LIST, userList);
}


export function* watchShowUser() {
    yield takeEvery(UserActionTypes.SHOW_USER, showUser);
}


function* userSaga() {
    yield all([fork(watchUserList), fork(watchShowUser)]);
}

export default userSaga;
