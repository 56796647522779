import React from 'react';

import Routes from './routes/Routes';



// Themes
// For Default import Theme.scss
import './assets/scss/Theme.scss';
import { Toaster } from 'react-hot-toast';

// For Dark import Theme-Dark.scss
// import './assets/scss/Theme-Dark.scss';

// configure fake backend


const App = () => {
    return (
        <>
            <Routes></Routes>
            <Toaster />
        </>
    );
};

export default App;
