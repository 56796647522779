import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import {
    privateDigitalShadeCards as privateDigitalShadeCardsApi,
    addPrivateDigitalShadeCard as addPrivateDigitalShadeCardApi,
    updatePrivateDigitalShadeCard as updatePrivateDigitalShadeCardApi,
    showPrivateDigitalShadeCard as showPrivateDigitalShadeCardApi,
    deletePrivateDigitalShadeCard as deletePrivateDigitalShadeCardApi,
} from '../../helpers';

// actions
import { privateDigitalShadeCardApiResponseSuccess, privateDigitalShadeCardApiResponseError } from './actions';

// constants
import { PrivateDigitalShadeCardActionTypes } from './constants';

interface PrivateDigitalShadeCardData {
    payload: {
        search: string;
        description: string;
        sortBy: string,
        limit: number,
        page: number,
        //
        id: string,
        status: string,
        title: string,
        email: string,
        phone: string,
        expiry: string,
        bookSize: string,
        password: string,
        companyName: string,
        images: Array<any>,
        pdf: string,
    };
    type: string;
}

/**
 * PrivateDigitalShadeCard list
 */
function* privateDigitalShadeCardList({ payload: {
    search,
    status,
    sortBy,
    limit,
    page
} }: PrivateDigitalShadeCardData): SagaIterator {
    try {

        const response = yield call(privateDigitalShadeCardsApi, {
            search,
            status,
            sortBy,
            limit,
            page
        });
        const responseData = {
            data: response.data.results,
            page: response.data.page,
            limit: response.data.limit,
            totalPages: response.data.totalPages,
            totalResults: response.data.totalResults,
        }
        yield put(privateDigitalShadeCardApiResponseSuccess({
            actionType: PrivateDigitalShadeCardActionTypes.PRIVATE_DIGITAL_SHADE_CARD_LIST,
            responseData
        }));
    } catch (error: any) {
        yield put(privateDigitalShadeCardApiResponseError(PrivateDigitalShadeCardActionTypes.PRIVATE_DIGITAL_SHADE_CARD_LIST, error));

    }
}

/**
 * Add PrivateDigitalShadeCard
 */
function* addPrivateDigitalShadeCard({ payload: {
    title,
    bookSize,
    email,
    phone,
    expiry,
    password,
    companyName,
    status,
    pdf,
    images } }: PrivateDigitalShadeCardData): SagaIterator {
    try {

        const response = yield call(addPrivateDigitalShadeCardApi, {
            bookSize,
            email,
            phone,
            expiry,
            password,
            companyName,
            title,
            status,
            pdf,
            images
        });
        const responseData = {
            data: response.data.data,
        }
        yield put(privateDigitalShadeCardApiResponseSuccess({
            actionType: PrivateDigitalShadeCardActionTypes.ADD_PRIVATE_DIGITAL_SHADE_CARD,
            responseData
        }

        ));
    } catch (error: any) {
        yield put(privateDigitalShadeCardApiResponseError(PrivateDigitalShadeCardActionTypes.ADD_PRIVATE_DIGITAL_SHADE_CARD, error));

    }
}

/**
 * Show User
 */
function* showPrivateDigitalShadeCard({ payload: {
    id,
} }: PrivateDigitalShadeCardData): SagaIterator {
    try {
        const response = yield call(showPrivateDigitalShadeCardApi, {}, id);
        const responseData = {
            data: response.data,
        }
        yield put(privateDigitalShadeCardApiResponseSuccess({
            actionType: PrivateDigitalShadeCardActionTypes.SHOW_PRIVATE_DIGITAL_SHADE_CARD,
            responseData
        }
        ));
    } catch (error: any) {
        yield put(privateDigitalShadeCardApiResponseError(PrivateDigitalShadeCardActionTypes.SHOW_PRIVATE_DIGITAL_SHADE_CARD, error));

    }
}

/**
 * Update PrivateDigitalShadeCard
 */
function* updatePrivateDigitalShadeCard({ payload: {
    id,
    title,
    email,
    phone,
    expiry,
    bookSize,
    password,
    companyName,
    status,
    pdf,
    images } }: PrivateDigitalShadeCardData): SagaIterator {
    try {
        const response = yield call(updatePrivateDigitalShadeCardApi, {
            status,
            bookSize,
            email,
            phone,
            expiry,
            password,
            companyName,
            title,
            images,
            pdf,
        }, id);
        const responseData = {
            data: response.data.data,
        }
        yield put(privateDigitalShadeCardApiResponseSuccess({
            actionType: PrivateDigitalShadeCardActionTypes.UPDATE_PRIVATE_DIGITAL_SHADE_CARD,
            responseData
        }
        ));
    } catch (error: any) {
        yield put(privateDigitalShadeCardApiResponseError(PrivateDigitalShadeCardActionTypes.UPDATE_PRIVATE_DIGITAL_SHADE_CARD, error));

    }
}
/**
 * Delete PrivateDigitalShadeCard
 */
function* deletePrivateDigitalShadeCard({ payload: { id } }: PrivateDigitalShadeCardData): SagaIterator {
    try {
        yield call(deletePrivateDigitalShadeCardApi, id);
        const responseData = {
            deletedId: id
        };
        yield put(privateDigitalShadeCardApiResponseSuccess({
            actionType: PrivateDigitalShadeCardActionTypes.DELETE_PRIVATE_DIGITAL_SHADE_CARD,
            responseData
        }));
    } catch (error: any) {
        yield put(privateDigitalShadeCardApiResponseError(PrivateDigitalShadeCardActionTypes.DELETE_PRIVATE_DIGITAL_SHADE_CARD, error));

    }
}



export function* watchPrivateDigitalShadeCardList() {
    yield takeEvery(PrivateDigitalShadeCardActionTypes.PRIVATE_DIGITAL_SHADE_CARD_LIST, privateDigitalShadeCardList);
}

export function* watchAddPrivateDigitalShadeCard() {
    yield takeEvery(PrivateDigitalShadeCardActionTypes.ADD_PRIVATE_DIGITAL_SHADE_CARD, addPrivateDigitalShadeCard);
}

export function* watchUpdatePrivateDigitalShadeCard() {
    yield takeEvery(PrivateDigitalShadeCardActionTypes.UPDATE_PRIVATE_DIGITAL_SHADE_CARD, updatePrivateDigitalShadeCard);
}
export function* watchShowPrivateDigitalShadeCard() {
    yield takeEvery(PrivateDigitalShadeCardActionTypes.SHOW_PRIVATE_DIGITAL_SHADE_CARD, showPrivateDigitalShadeCard);
}

export function* watchDeletePrivateDigitalShadeCard() {
    yield takeEvery(PrivateDigitalShadeCardActionTypes.DELETE_PRIVATE_DIGITAL_SHADE_CARD, deletePrivateDigitalShadeCard);
}



function* privateDigitalShadeCardSaga() {
    yield all([fork(watchPrivateDigitalShadeCardList), fork(watchAddPrivateDigitalShadeCard), fork(watchShowPrivateDigitalShadeCard), fork(watchUpdatePrivateDigitalShadeCard), fork(watchDeletePrivateDigitalShadeCard)]);
}

export default privateDigitalShadeCardSaga;
