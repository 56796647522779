import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import {
    jobSubmissions as jobSubmissionssApi,
} from '../../../helpers';

// actions
import { jobSubmissionsApiResponseSuccess, jobSubmissionsApiResponseError } from './actions';

// constants
import { JobSubmissionsActionTypes } from './constants';

interface JobSubmissionsData {
    payload: {
        search: string;
        sortBy: string,
        limit: number,
        page: number,
        //
        id: string,
        title: string
    };
    type: string;
}

/**
 * JobSubmissions list
 */
function* jobSubmissionsList({ payload: {
    search,
    sortBy,
    limit,
    page
} }: JobSubmissionsData): SagaIterator {
    try {

        const response = yield call(jobSubmissionssApi, {
            search,
            sortBy,
            limit,
            page
        });
        const responseData = {
            data: response.data.results,
            page: response.data.page,
            limit: response.data.limit,
            totalPages: response.data.totalPages,
            totalResults: response.data.totalResults,
        }
        
        yield put(jobSubmissionsApiResponseSuccess({
            actionType: JobSubmissionsActionTypes.JOB_SUBMISSIONS_LIST,
            responseData
        }));
    } catch (error: any) {
        yield put(jobSubmissionsApiResponseError(JobSubmissionsActionTypes.JOB_SUBMISSIONS_LIST, error));

    }
}

export function* watchJobSubmissionsList() {
    yield takeEvery(JobSubmissionsActionTypes.JOB_SUBMISSIONS_LIST, jobSubmissionsList);
}

function* jobSubmissionsSaga() {
    yield all([fork(watchJobSubmissionsList)]);
}

export default jobSubmissionsSaga;
