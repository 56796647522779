import { APICore } from './apiCore';
const api = new APICore();

// jobSubmissions
function contactUsSubmissions(params: {
    search: string,
    sortBy: string,
    limit: number,
    page: number,
}) {
    const baseUrl = `/contact-us`;
    return api.get(`${baseUrl}`, params);

}

export {
    contactUsSubmissions
};
