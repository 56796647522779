import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import {
    createYourOwn as createYourOwnsApi,
} from '../../helpers';

// actions
import { createYourOwnApiResponseSuccess, createYourOwnApiResponseError } from './actions';

// constants
import { CreateYourOwnActionTypes } from './constants';

interface CreateYourOwnData {
    payload: {
        search: string;
        sortBy: string,
        limit: number,
        page: number,
        //
        id: string,
        title: string
    };
    type: string;
}

/**
 * CreateYourOwn list
 */
function* createYourOwnList({ payload: {
    search,
    sortBy,
    limit,
    page
} }: CreateYourOwnData): SagaIterator {
    try {

        const response = yield call(createYourOwnsApi, {
            search,
            sortBy,
            limit,
            page
        });
        const responseData = {
            data: response.data.results,
            page: response.data.page,
            limit: response.data.limit,
            totalPages: response.data.totalPages,
            totalResults: response.data.totalResults,
        }
        
        yield put(createYourOwnApiResponseSuccess({
            actionType: CreateYourOwnActionTypes.CREATE_YOUR_OWN_LIST,
            responseData
        }));
    } catch (error: any) {
        yield put(createYourOwnApiResponseError(CreateYourOwnActionTypes.CREATE_YOUR_OWN_LIST, error));

    }
}

export function* watchCreateYourOwnList() {
    yield takeEvery(CreateYourOwnActionTypes.CREATE_YOUR_OWN_LIST, createYourOwnList);
}

function* createYourOwnSaga() {
    yield all([fork(watchCreateYourOwnList)]);
}

export default createYourOwnSaga;
