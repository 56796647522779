// constants
import { NewsAndEventActionTypes } from './constants';

export interface NewsAndEventActionType {
    type:
    | NewsAndEventActionTypes.API_RESPONSE_SUCCESS
    | NewsAndEventActionTypes.API_RESPONSE_ERROR
    | NewsAndEventActionTypes.NEWS_AND_EVENT_LIST
    | NewsAndEventActionTypes.ADD_NEWS_AND_EVENT
    | NewsAndEventActionTypes.UPDATE_NEWS_AND_EVENT
    | NewsAndEventActionTypes.SHOW_NEWS_AND_EVENT
    | NewsAndEventActionTypes.DELETE_NEWS_AND_EVENT
    payload: {} | string;
}

interface FiltersData {
    status: string,
    sortBy: string,
    limit: number,
    page: number,
    search: string
};

interface FormData {
    status: string,
    id: string,
    title: string,
    image: string,
    shortContent: string
    content: string
};


interface ResponseData {
    actionType: string,
    responseData: any,
}


// common success
export const newsAndEventApiResponseSuccess = ({ actionType, responseData }: ResponseData): NewsAndEventActionType => ({
    type: NewsAndEventActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, responseData },
});
// common error
export const newsAndEventApiResponseError = (actionType: string, error: string): NewsAndEventActionType => ({
    type: NewsAndEventActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const createNewsAndEvent = ({
    title,
    image,
    shortContent,
    content,
    status
}: FormData): NewsAndEventActionType => ({
    type: NewsAndEventActionTypes.ADD_NEWS_AND_EVENT,
    payload: {
        title,
        image,
        shortContent,
        content,
        status
    },
});


export const newsAndEventList = ({
    status,
    sortBy,
    limit,
    page,
    search,
}: FiltersData): NewsAndEventActionType => ({
    type: NewsAndEventActionTypes.NEWS_AND_EVENT_LIST,
    payload: {
        search,
        status,
        sortBy,
        limit,
        page,
    },
});

export const showNewsAndEvent = (id: string): NewsAndEventActionType => ({
    type: NewsAndEventActionTypes.SHOW_NEWS_AND_EVENT,
    payload: { id },
});

export const destroyNewsAndEvent = (id: string): NewsAndEventActionType => ({
    type: NewsAndEventActionTypes.DELETE_NEWS_AND_EVENT,
    payload: { id },
});

export const updateNewsAndEvent = ({
    id,
    title,
    image,
    shortContent,
    content,
    status
}: FormData): NewsAndEventActionType => ({
    type: NewsAndEventActionTypes.UPDATE_NEWS_AND_EVENT,
    payload: {
        id,
        title,
        image,
        shortContent,
        content,
        status
    },
});



