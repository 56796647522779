// constants
import { NewsAndEventActionTypes } from './constants';

const INIT_STATE = {
    newsAndEvents: [],
    newsAndEvent: {},
    page: 1,
    limit: 10,
    totalPages: 0,
    totalResults: 0,
    loading: false,
    hasErrors: false,
    submitted: false,
    isDeleted: false,
};

interface NewsAndEventActionType {
    type:
    | NewsAndEventActionTypes.API_RESPONSE_SUCCESS
    | NewsAndEventActionTypes.API_RESPONSE_ERROR
    | NewsAndEventActionTypes.NEWS_AND_EVENT_LIST
    | NewsAndEventActionTypes.ADD_NEWS_AND_EVENT
    | NewsAndEventActionTypes.SHOW_NEWS_AND_EVENT
    | NewsAndEventActionTypes.UPDATE_NEWS_AND_EVENT

    payload: {
        actionType?: string;
        responseData?: any;
        error?: any;
        validationError?: { error: string; key: string; }[];
        hasErrors: boolean,
        submitted: boolean,
        isDeleted: boolean,
    };
}

interface State {
    newsAndEvents: any;
    newsAndEvent: any;
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
}

const NewsAndEvent = (state: State = INIT_STATE, action: NewsAndEventActionType): any => {
    switch (action.type) {
        case NewsAndEventActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload?.actionType) {  
                case NewsAndEventActionTypes.NEWS_AND_EVENT_LIST: {
                    return {
                        ...state,
                        newsAndEvents: action.payload?.responseData?.data,
                        page: action.payload?.responseData?.page,
                        limit: action.payload?.responseData?.limit,
                        totalPages: action.payload?.responseData?.totalPages,
                        totalResults: action.payload?.responseData?.totalResults,
                        loading: true,
                        submitted: false,
                    };
                }
                case NewsAndEventActionTypes.ADD_NEWS_AND_EVENT: {
                    return {
                        ...state,
                        newsAndEvents: [...state.newsAndEvents, action.payload?.responseData?.data],
                        totalResults: (action.payload?.responseData?.totalResults ? (action.payload?.responseData?.totalResults + 1) : 0),
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case NewsAndEventActionTypes.SHOW_NEWS_AND_EVENT: {
                    return {
                        ...state,
                        newsAndEvent: action.payload?.responseData?.data,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case NewsAndEventActionTypes.UPDATE_NEWS_AND_EVENT: {
                    const updatedNewsAndEvent = action.payload?.responseData?.data;
                    const allSubs = state.newsAndEvents;
                    const updatedSubs= allSubs.map((cat: any) => cat.id === updatedNewsAndEvent.id ? updatedNewsAndEvent : cat);
                    return {
                        ...state,
                        newsAndEvents: updatedSubs,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case NewsAndEventActionTypes.DELETE_NEWS_AND_EVENT: {
                    const deletedId = action.payload?.responseData?.deletedId;
                    const allSubs = state.newsAndEvents;
                    const updatedSubs = allSubs.filter((cat: any) => cat.id !== deletedId);
                    return {
                        ...state,
                        newsAndEvents: updatedSubs,
                        loading: true,
                        hasErrors: false,
                        submitted: false,
                        isDeleted: true,
                    };
                }
                default:
                    return { ...state };
            }

        case NewsAndEventActionTypes.API_RESPONSE_ERROR:
            switch (action.payload?.actionType) {
                case NewsAndEventActionTypes.NEWS_AND_EVENT_LIST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: false,
                    };
                }
                case NewsAndEventActionTypes.ADD_NEWS_AND_EVENT: {
                    return {
                        ...state,
                        error: action.payload?.error,
                        validationError: action.payload?.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case NewsAndEventActionTypes.SHOW_NEWS_AND_EVENT: {
                    return {
                        ...state,
                        error: action.payload?.error,
                        validationError: action.payload?.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case NewsAndEventActionTypes.UPDATE_NEWS_AND_EVENT: {
                    return {
                        ...state,
                        error: action.payload?.error,
                        validationError: action.payload?.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default NewsAndEvent;
