// constants
import { ChatFormSetupActionTypes } from './constants';

export interface ChatFormSetupActionType {
    type:
    | ChatFormSetupActionTypes.API_RESPONSE_SUCCESS
    | ChatFormSetupActionTypes.API_RESPONSE_ERROR
    | ChatFormSetupActionTypes.CHAT_SETUP_LIST
    | ChatFormSetupActionTypes.ADD_CHAT_SETUP
    | ChatFormSetupActionTypes.UPDATE_CHAT_SETUP
    | ChatFormSetupActionTypes.SHOW_CHAT_SETUP
    | ChatFormSetupActionTypes.DELETE_CHAT_SETUP
    payload: {} | string;
}

interface FormData {
    _id: string,
    question: string,
    type: string,
    parentId: string
};


interface ResponseData {
    actionType: string,
    responseData: any,
}


// common success
export const chatFormSetupApiResponseSuccess = ({ actionType, responseData }: ResponseData): ChatFormSetupActionType => ({
    type: ChatFormSetupActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, responseData },
});
// common error
export const chatFormSetupApiResponseError = (actionType: string, error: string): ChatFormSetupActionType => ({
    type: ChatFormSetupActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const createChatFormSetup = ({
    question,
    type,
    parentId
}: FormData): ChatFormSetupActionType => ({
    type: ChatFormSetupActionTypes.ADD_CHAT_SETUP,
    payload: {
        question,
        type,
        parentId
    },
});


export const chatFormSetupList = (): ChatFormSetupActionType => ({
    type: ChatFormSetupActionTypes.CHAT_SETUP_LIST,
    payload: {},
});

export const showChatFormSetup = (_id: string): ChatFormSetupActionType => ({
    type: ChatFormSetupActionTypes.SHOW_CHAT_SETUP,
    payload: { _id },
});

export const destroyChatFormSetup = (_id: string): ChatFormSetupActionType => ({
    type: ChatFormSetupActionTypes.DELETE_CHAT_SETUP,
    payload: { _id },
});

export const updateChatFormSetup = ({
    _id,
    question,
    type,
    parentId
}: FormData): ChatFormSetupActionType => ({
    type: ChatFormSetupActionTypes.UPDATE_CHAT_SETUP,
    payload: {
        _id,
        question,
        type,
        parentId
    },
});



