import { APICore } from './apiCore';
const api = new APICore();

// digitalShadeCard
function digitalShadeCards(params: {
    status: string,
    search: string,
    sortBy: string,
    limit: number,
    page: number,
}) {
    const baseUrl = `/digital-shade-card`;
    return api.get(`${baseUrl}`, params);

}
function addDigitalShadeCard(params: {
    status: string,
    title: string,
    bookSize: string,
    category: string,
    images: Array<any>,
    pdf: string,
}) {
    const baseUrl = `/digital-shade-card`;
    return api.create(`${baseUrl}`, params);
}

function updateDigitalShadeCard(params: {
    status: string,
    title: string,
    bookSize: string,
    category: string,
    images: Array<any>,
    pdf: string,
}, id: string) {
    const baseUrl = `/digital-shade-card/${id}`;
    return api.updatePatch(`${baseUrl}`, params);

}

function deleteDigitalShadeCard(id: string) {
    const baseUrl = `/digital-shade-card/${id}`;
    return api.delete(`${baseUrl}`);
}

function showDigitalShadeCard(params: {}, id: string) {
    const baseUrl = `/digital-shade-card/${id}`;
    return api.get(`${baseUrl}`, params);
}


function uploadDigitalShadeCardImage(formData: FormData) {
    const baseUrl = `/digital-shade-card/uploadImages`;
    return api.uploadMultipleFile(`${baseUrl}`, formData);
}

function removeDigitalShadeCardImage(params: {
    fileName: string,
    folder: string
    productId: string
}) {
    const baseUrl = `/digital-shade-card/deleteImage`;
    return api.create(`${baseUrl}`, params);

}

export {
    digitalShadeCards,
    addDigitalShadeCard,
    updateDigitalShadeCard,
    deleteDigitalShadeCard,
    showDigitalShadeCard,
    uploadDigitalShadeCardImage,
    removeDigitalShadeCardImage,
};
