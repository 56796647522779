// constants
import { CreateYourOwnActionTypes } from './constants';

export interface CreateYourOwnActionType {
    type:
    | CreateYourOwnActionTypes.API_RESPONSE_SUCCESS
    | CreateYourOwnActionTypes.API_RESPONSE_ERROR
    | CreateYourOwnActionTypes.CREATE_YOUR_OWN_LIST
    payload: {} | string;
}

interface FiltersData {
    sortBy: string,
    limit: number,
    page: number,
    search: string
};

interface ResponseData {
    actionType: string,
    responseData: any,
}


// common success
export const createYourOwnApiResponseSuccess = ({ actionType, responseData }: ResponseData): CreateYourOwnActionType => ({
    type: CreateYourOwnActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, responseData },
});
// common error
export const createYourOwnApiResponseError = (actionType: string, error: string): CreateYourOwnActionType => ({
    type: CreateYourOwnActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const createYourOwnList = ({
    sortBy,
    limit,
    page,
    search,
}: FiltersData): CreateYourOwnActionType => ({
    type: CreateYourOwnActionTypes.CREATE_YOUR_OWN_LIST,
    payload: {
        search,
        sortBy,
        limit,
        page,
    },
});




