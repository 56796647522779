export enum InvestorRelationActionTypes {
    API_RESPONSE_SUCCESS = '@@investorRelation/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@investorRelation/API_RESPONSE_ERROR',
    INVESTOR_RELATION_LIST = '@@investorRelation/INVESTOR_RELATION_LIST',
    ADD_INVESTOR_RELATION = '@@investorRelation/ADD_INVESTOR_RELATION',
    SHOW_INVESTOR_RELATION = '@@investorRelation/SHOW_INVESTOR_RELATION',
    UPDATE_INVESTOR_RELATION = '@@investorRelation/UPDATE_INVESTOR_RELATION',
    DELETE_INVESTOR_RELATION = '@@investorRelation/DELETE_INVESTOR_RELATION',
}

