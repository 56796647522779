import { APICore } from './apiCore';
const api = new APICore();

// jobSubmissions
function chatSubmissions(params: {
    search: string,
    sortBy: string,
    limit: number,
    page: number,
}) {
    const baseUrl = `/chat-submissions`;
    return api.get(`${baseUrl}`, params);

}

export {
    chatSubmissions
};
